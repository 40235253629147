import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import DashboardNav from "./DashboardNav";
import CustomDrawer from "../shared/drawer/CustomDrawer";

const Layout = () => {
  const tokenString = localStorage.getItem("token_doctor");
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    if (!tokenString) {
      navigate("/");
    }
  }, [tokenString]);

  return (
    <>
      <div className=" flex bg-[#F4F7FE] h-screen">
        <div className=" hidden md:block">
          <Sidebar />
        </div>
        <CustomDrawer
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          className=""
          width={245}
        >
          <div className=" bg-white">
            <Sidebar />
          </div>
        </CustomDrawer>
        <div className="bg-[#F4F7FE] w-full h-screen overflow-y-auto">
          <DashboardNav setDrawerVisible={setDrawerVisible} />
          <div className=" w-full xl:px-[20px] px-4 mt-4 ">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;

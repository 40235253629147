import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../shared/modal/DeleteModal";
import toast from "react-hot-toast";
import HeadEdit from "./HeadEdit";
import { useDeleteHeadMutation } from "../../redux/features/head/headApi";
import { FiEdit } from "react-icons/fi";

const HeadAction = ({ row, refetch }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteHead, { isLoading, error, isSuccess }] = useDeleteHeadMutation();

  useEffect(() => {
    if (isSuccess) {
      const message = "Head delete successfully";
      toast.success(message);
      refetch();
      setDeleteModal(false);
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error, refetch]);

  const handelDelete = async () => {
    await deleteHead(row?._id);
  };

  return (
    <>
      <div className="flex items-center gap-1">
        <button
          onClick={() => setOpen(true)}
          className=" text-xl hover:text-primary duration-200"
        >
          <FiEdit />
        </button>
        <button
          onClick={() => setDeleteModal(true)}
          className=" text-xl hover:text-red-500 duration-200"
        >
          <RiDeleteBin6Line />
        </button>
      </div>

      <DeleteModal
        modalOPen={deleteModal}
        onDelete={() => handelDelete()}
        setModalOpen={setDeleteModal}
        title={"Delete Head!"}
        title2={
          "Are you sure you want to delete this head? This action cannot be undone."
        }
        isLoading={isLoading}
      />

      <HeadEdit open={open} setOpen={setOpen} refetch={refetch} row={row} />
    </>
  );
};

export default HeadAction;

import React, { useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import CustomTable from "../../components/shared/table/CustomTable";
import BreadCrumb from "../../components/shared/BreadCrumb";
import { useGetProfileQuery } from "../../redux/features/profile/profileApi";
import DuePaymentAction from "../../components/sales/DuePaymentAction";

const TodayDue = () => {
  const [page, setPage] = useState(1);
  const limit = 10;


  const { data, isLoading, refetch } = useGetProfileQuery(
    `status=due&today=true`,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  console.log(data);


  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Get the date part 'YYYY-MM-DD'
  };
  
  // Helper function to convert ISO date string to 'YYYY-MM-DD' format
  const convertISOToDateInputFormat = (isoDateString) => {
    return new Date(isoDateString).toISOString().split("T")[0];
  };

  const compareWithToday = (isoDateString) => {
    const todayDate = getTodayDate(); // Get today's date
    const targetDate = convertISOToDateInputFormat(isoDateString); // Convert the ISO date to 'YYYY-MM-DD'
    return todayDate === targetDate; // Return true if dates match, false otherwise
  };
  

  const totalItems = data?.item;

  const formatDate = (date) => {
    const currentDate = new Date(date);
    return currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
    });
  };
  const columns = [
    {
      title: "Line Id",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.line_id}
        </span>
      ),
      width: "100px",
    },
    {
      title: "Customer Name",
      key: "id",
      render: (row) => (
        <div className=" flex flex-col">
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.name}
          </span>
        </div>
      ),
      width: "150px",
    },

    {
      title: "Phone",
      key: "id",
      render: (row) => (
        <div className=" flex flex-col">
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.phone}
          </span>
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.other_phone}
          </span>
        </div>
      ),
    },
    {
      title: "Line Sector",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.line_sector?.title}
        </span>
      ),
    },
    {
      title: "Bill Expire Date",
      key: "id",
      render: (row) => (
        <span className={`text-[14px] capitalize font-normal ${compareWithToday(row?.last_pay_date) ?  "text-info" : "text-red-500"}`}>
          {formatDate(row?.last_pay_date)}
        </span>
      ),
    },
    {
      title: "Due Month",
      key: "id",
      render: (row) => (
        <div className=" flex items-center flex-col gap-1">
          {row?.due_months?.map((item, index) => (
            <span
              key={index}
              className=" text-[14px] capitalize font-normal text-info"
            >
              {item}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "Due Amount",
      render: (product) => (
        <div className="flex flex-col text-info">
          <span className="text-[14px] font-medium">
            <span className=" text-base">৳</span> {product.amount_due}
          </span>
        </div>
      ),
    },
    {
      title: "Pay",
      render: (product) => (
        <>
          <DuePaymentAction row={product} refetch={refetch} />
        </>
      ),
    },
  ];

  return (
    <div className=" pb-8">
      <BreadCrumb
        title={"Payment Due List"}
        links={[
          { title: "Admins", url: "/admin/dashboard" },
          { title: "Payment Due", url: "#" },
        ]}
      />

      {isLoading ? (
        <>
          <div className=" flex items-center justify-center w-full h-[80vh]">
            <ImSpinner2 className=" animate-spin w-12 h-12" />
          </div>
        </>
      ) : (
        <div className=" bg-white shadow-sm mt-5 rounded-md">
          <CustomTable columns={columns} data={data?.data} />
          <div className=" py-4 flex items-center justify-between px-4">
            <div className="text-light-black font-medium text-[14px] text-[#68769F]">
              {`Showing ${page * limit - limit + 1} - ${Math.min(
                page * limit,
                totalItems
              )} of ${totalItems}`}
            </div>
            {/* <Pagination
              defaultCurrent={page}
              total={totalItems}
              pageSize={limit}
              onChange={PaginationChange}
              showSizeChanger={false}
            /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default TodayDue;

import React, { useRef } from "react";
import { FiPrinter } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im";
import ReactToPrint from "react-to-print";
import { useGetSingleHistoryQuery } from "../../redux/features/history/historyApi";
import { FaLocationDot } from "react-icons/fa6";
import { formatDate } from "../../utils/commonFun";

const InvoicePage = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetSingleHistoryQuery(id);
  const componentRef = useRef();
  const history = data?.history;

  const formatDate2 = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-GB", options); // Formats the date as "dd/mm/yyyy"

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time

    return `${formattedDate} ${hours}:${minutes} ${ampm}`;
  };

  const now = new Date();
  const formattedDate = formatDate2(now);

  return (
    <div className=" pb-7">
      {isLoading ? (
        <>
          <div className=" flex items-center justify-center w-full h-[80vh]">
            <ImSpinner2 className=" animate-spin w-12 h-12" />
          </div>
        </>
      ) : (
        <div className=" bg-white shadow-md w-full rounded-lg p-6 px-10">
          <div className=" flex items-center justify-between">
            <h2 className=" font-semibold text-xl">Payment Invoice</h2>
            <div className="">
              <ReactToPrint
                trigger={() => (
                  <button className=" flex items-center justify-center gap-2 text-sm py-2 px-5 rounded-md bg-blue-500 text-white">
                    <FiPrinter /> Print
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>

          <div className=" mt-5 p-5" ref={componentRef}>
            <div className=" flex items-center border-b border-b-gray-300 py-4  justify-between">
              <img src="/image/logo.png" alt="" className=" w-[250px]" />
              {/* <img
                src="/image/barcode.png"
                alt=""
                className=" w-[220px] h-[60px]"
              /> */}
              <div className=" flex flex-col w-[320px] gap-1 justify-end items-end">
                <h2 className=" font-semibold text-xl">
                  আনন্দ ক্যাবল নেটওয়ার্ক
                </h2>
                <p className=" text-sm font-medium"> 01764-938199</p>
                <p className=" text-sm font-medium">01777-673858</p>
                <span className="text-sm font-mono  font-medium">
                বুরগী,পাক-শ্রী-রামপুর,কচুয়া,চাঁদপুর।
                </span>

              </div>
            </div>

            <div className=" mt-5">
              <h3 className=" text-2xl font-medium text-center">
                Payment Invoice
              </h3>
              <div className=" mt-4 flex items-start justify-between">
                <div>
                  <h2 className=" text-base font-semibold uppercase">
                    {history?.profile?.name}
                  </h2>
                  <div className=" flex items-center mt-2  text-sm gap-1">
                    <FaPhoneAlt />
                    <span>{history?.profile?.phone}</span>
                  </div>
                  <div className=" flex items-center mt-2  text-sm gap-1">
                    <FaLocationDot />
                    <span>{history?.profile?.address}</span>
                  </div>
                </div>

                <div>
                  <div className=" flex items-center gap-1">
                    <div className=" w-[10px] rounded-full bg-primary/70 h-[10px]"></div>
                    <h3 className=" font-bold text-sm">Line Id :</h3>
                    <span className=" font-normal text-sm">
                      {history?.profile?.line_id}
                    </span>
                  </div>

                  <div className=" flex items-center gap-1 mt-2">
                    <div className=" w-[10px] rounded-full bg-primary/70 h-[10px]"></div>
                    <h3 className=" font-bold text-sm">Bill Expire Date:</h3>
                    <span className=" font-normal text-sm">
                      {formatDate(history?.profile?.bill_remainder_date)}
                    </span>
                  </div>

                  <div className=" flex items-center gap-1 mt-2">
                    <div className=" w-[10px] rounded-full bg-primary/70 h-[10px]"></div>
                    <h3 className=" font-bold text-sm">Status :</h3>
                    <span className=" font-normal text-sm">
                      {history?.total_due === history?.payment
                        ? "Paid"
                        : "Unpaid"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className=" mt-10">
              <div className=" flex px-5 py-3 items-center bg-primary/50 justify-between">
                <h2 className=" text-sm font-bold">Payment Date</h2>
                <h2 className=" text-sm font-bold">Due Month</h2>
                <h2 className=" text-sm font-bold">Payment Method</h2>
                <h2 className=" text-sm font-bold">Amount</h2>
              </div>

              <div className=" flex px-5 py-3 items-center bg-primary/10 justify-between">
                <h2 className=" text-sm font-normal">
                  {formatDate(history?.createdAt)}
                </h2>
                <h2 className=" text-sm font-normal text-center">
                  {history?.due_months?.map((item) => item)}
                </h2>
                <h2 className=" text-sm font-normal text-center">
                  {history?.method}
                </h2>
                <h2 className=" text-sm font-normal">৳ {history?.payment}</h2>
              </div>

              <div className=" mt-5 flex items-start px-5 justify-between">
                <div className=" w-[50%]">
                  {/* <p className=" font-normal text-sm">{history?.note}</p> */}
                </div>

                <div className=" w-[20%] text-sm">
                  <div className=" flex  items-center justify-between">
                    <h2>Total Due</h2>
                    <h2>৳ {history?.total_due ? history?.total_due : 0}</h2>
                  </div>

                  <div className=" flex  items-center mt-3 justify-between">
                    <h2>Paid</h2>
                    <h2>৳ {history?.payment}</h2>
                  </div>

                  <div className=" w-full h-[1px] bg-gray-300 mt-3"></div>

                  <div className=" flex items-center mt-3 justify-between">
                    <h2>Due</h2>
                    <h2>
                      ৳{" "}
                      {(history?.total_due ? history?.total_due : 0) -
                        history?.payment}
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div className=" mt-16 flex flex-col w-full gap-2 pb-5">
              <div className=" flex items-center justify-between">
                <h2 className=" font-normal text-sm">
                  Email : <span>anandaaviation@yahoo.com, tofayel2009@gmail.com</span>
                </h2>
                <h2 className=" font-normal text-sm">
                  Print Date : <span> {formattedDate}</span>
                </h2>
              </div>
              <div className=" w-full h-[1px] bg-gray-300"></div>
              <div className=" flex items-center justify-between">
                <h2 className=" font-normal text-sm">
                  Thank you for believing us!
                </h2>
                <h2 className=" font-normal text-sm">
                  Software by: https://www.softriple.com
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoicePage;

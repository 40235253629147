import { Select } from "antd";
import React from "react";

const CustomSelectMultiple = ({
  label,
  value,
  setValue,
  placeholder,
  options,
  required = true,
}) => {
  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <div className="flex flex-col items-start w-full mt-3">
      <label
        htmlFor="custom-select"
        className="mb-1.5 font-medium text-sm text-dark-gray"
      >
        {label}
      </label>
      <Select
        mode="multiple"
        id="custom-select"
        placeholder={placeholder}
        optionFilterProp="label"
        required={required}
        value={value}
        className="custom-select-class h-[45px] font-normal  rounded-md w-full text-sm outline-none border-[1px] focus:border-primary focus:bg-transparent"
        options={options}
        onChange={handleChange}
      />
    </div>
  );
};

export default CustomSelectMultiple;

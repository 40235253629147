import React from "react";

const DashboardCard = ({
  title,
  amount,
  className,
  icon,
  iconColor = "",
  sub = "",
  taka =false,
}) => {
  return (
    <div
      className={`bg-white p-3 border hover:scale-105 duration-300 cursor-pointer shadow-md rounded-md border-b-2 ${className}`}
    >
      <div className=" flex items-center justify-between">
        <div className="">
          <p className="md:text-[15px] text-sm text-slate-600 font-semibold">
            {title}
          </p>
          <div className=" flex items-center mt-1 gap-2">
       
            <h2 className=" font-bold text-lg">{taka ? "৳": ""} {amount}</h2>
            {sub && <p className=" font-normal text-[12px]"> ({sub})</p>}
          </div>
        </div>
        <div>
          <div
            className={`${iconColor} h-12 w-12 rounded-md  flex justify-center items-center`}
          >
            {icon}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;

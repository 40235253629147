import { userLoggedIn, userLoggedOut, userRegistration } from "./authSlice";
import { apiSlice } from "../api/apiSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addRegister: builder.mutation({
      query: (data) => ({
        url: "user/signup",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            userRegistration({
              token: result.data.token,
              code: result.data.code,
            })
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
    activation: builder.mutation({
      query: ({ token, activitonnCode }) => ({
        url: "activate-user",
        method: "POST",
        body: {
          token,
          activitonnCode,
        },
      }),
    }),
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: "user/login",
        method: "POST",
        body: {
          email,
          password,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          localStorage.setItem(
            "token_doctor",
            JSON.stringify(result?.data?.data?.accessToken)
          );
          dispatch(
            userLoggedIn({
              accessToken: result?.data?.data?.accessToken,
              user: result?.data?.data?.user,
            })
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
    logout: builder.query({
      query: (data) => ({
        url: "logout",
        method: "GET",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          localStorage.removeItem("token");
          dispatch(userLoggedOut());
        } catch (error) {
          console.log(error);
        }
      },
    }),
    update: builder.mutation({
      query: ({ id, data }) => ({
        url: `user/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    updateAvailability: builder.mutation({
      query: ({ id, data }) => ({
        url: `user/availability/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    getAllUser: builder.query({
      query: (query) => ({
        url: `user?${query}`,
        method: "GET",
      }),
    }),
    getAllCollection: builder.query({
      query: (query) => ({
        url: `history?${query}`,
        method: "GET",
      }),
    }),
    getVendorHistory: builder.query({
      query: (query) => ({
        url: `history/vendor?${query}`,
        method: "GET",
      }),
    }),
    getUser: builder.query({
      query: (id) => ({
        url: `user/${id}`,
        method: "GET",
      }),
    }),
    getDashboard: builder.query({
      query: () => ({
        url: `user/dashboard`,
        method: "GET",
      }),
    }),
    getDashboardTotal: builder.query({
      query: (query) => ({
        url: `user/dashboard/total?${query}`,
        method: "GET",
      }),
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `user/${id}`,
        method: "DELETE",
      }),
    }),
    passwordChange: builder.mutation({
      query: (data) => ({
        url: `user/change-password`,
        method: "PATCH",
        body: data,
      }),
    }),
    forgetChange: builder.mutation({
      query: (data) => ({
        url: `user/forgot-password`,
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `user/reset-password`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useAddRegisterMutation,
  useActivationMutation,
  useLoginMutation,
  useLogoutQuery,
  useUpdateMutation,
  useUpdateAvailabilityMutation,
  useGetUserQuery,
  useGetAllUserQuery,
  useDeleteUserMutation,
  useGetDashboardQuery,
  useGetAllCollectionQuery,
  useGetVendorHistoryQuery,
  usePasswordChangeMutation,
  useForgetChangeMutation,
  useResetPasswordMutation,
  useGetDashboardTotalQuery,
} = authApi;

import { apiSlice } from "../api/apiSlice";


export const profileApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createProfile: builder.mutation({
      query: (data) => ({
        url: "profile/create",
        method: "POST",
        body: data,
      }),
    }),
    getProfile: builder.query({
      query: (query) => ({
        url: `profile?${query}`,
        method: "GET",
      }),
    }),
    getDashboardMain: builder.query({
      query: (query) => ({
        url: `dashboard?${query}`,
        method: "GET",
      }),
    }),
    getSingleProfile: builder.query({
      query: (id) => ({
        url: `profile/${id}`,
        method: "GET",
      }),
    }),
    editProfile: builder.mutation({
      query: ({ id, data }) => ({
        url: `profile/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteProfile: builder.mutation({
      query: (id) => ({
        url: `profile/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
    useCreateProfileMutation,
    useGetProfileQuery,
    useGetSingleProfileQuery,
    useEditProfileMutation,
    useDeleteProfileMutation,
    useGetDashboardMainQuery
} = profileApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userLoggedIn } from "../auth/authSlice";

// https://anondo-avation-server.vercel.app

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://anondo-avation-server.vercel.app/api/v1/",
    prepareHeaders: (headers) => {
      // Get the token from localStorage
      const tokenString = localStorage.getItem("token_doctor");

      if (tokenString !== null) {
        // Set the Bearer token in the headers
        const token = JSON.parse(tokenString);
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    refreshToken: builder.query({
      query: (data) => ({
        url: "refresh-token",
        method: "GET",
      }),
    }),
    loadUser: builder.query({
      query: (data) => ({
        url: "user/me",
        method: "GET",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          dispatch(
            userLoggedIn({
              accessToken: result?.data?.data.access_token,
              user: result?.data?.data,
            })
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useRefreshTokenQuery, useLoadUserQuery } = apiSlice;

import React from "react";
import CustomTable from "../shared/table/CustomTable";
import HistoryAction from "../allHistory/HistoryAction";
import { formatDate } from "../../utils/commonFun";

const DashboardManpower = ({ data,refetch }) => {
  const columns = [
    {
      title: "Line Id",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.profile?.line_id}
        </span>
      ),
      width: "100px",
    },
    {
      title: "Customer Name",
      key: "id",
      render: (row) => (
        <div className=" flex flex-col">
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.profile?.name}
          </span>
        </div>
      ),
      width: "150px",
    },
    {
      title: "Amount",
      render: (row) => (
        <div className="flex flex-col text-info">
          <span className="text-[14px] font-medium">
            <span className=" text-base">৳</span> {row?.payment}
          </span>
        </div>
      ),
    },
    {
      title: " Method",
      render: (row) => (
        <div className="flex flex-col text-info">
        <span className="text-[14px] font-medium">{row?.method}</span>
      </div>
      ),
    },
    {
      title: "Month",
      render: (row) => (
        <div className=" flex items-start flex-col gap-1">
          {row?.due_months?.map((item, index) => (
            <span
              key={index}
              className=" text-[14px] capitalize font-normal text-info"
            >
              {item}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "Payment Date",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {formatDate(row?.createdAt)}
        </span>
      ),
    },
    {
      title: "Note",
      render: (row) => (
        <div className="flex flex-col text-info">
          <span className="text-[14px] font-medium">{row?.note}</span>
        </div>
      ),
      width: "200px",
    },
    {
      title: "Action",
      render: (product) => (
        <>
          <HistoryAction row={product} refetch={refetch} />
        </>
      ),
    },
  ];
  return (
    <div>
      <div className=" bg-white shadow-sm mt-8 rounded-md">
        <div className="p-5 flex items-center gap-2">
          <h2 className=" text-xl font-semibold"> Payment List </h2>{" "}
          <span className=" text-sm font-medium text-blue-500">
            Latest 10 data
          </span>
        </div>
        <CustomTable columns={columns} data={data} />
      </div>
    </div>
  );
};

export default DashboardManpower;

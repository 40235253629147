import React, { useEffect, useState } from "react";
import HeadingSelect from "../shared/input/HeadingSelect";
import { IoSearch } from "react-icons/io5";
import ExpenseDownload from "./ExpenseDownload";

const ExpenseReporteFilter = ({
  setQuery,
  page,
  limit,
  setPage,
  refetch,
  query,
  totalAmount,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [open, setOpen] = useState(false);
  const [downloadQuery,setDownloadQuery]=useState("")

  console.log(open);

  const options3 = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "This Week",
      value: "thisweek",
    },
    {
      label: "Last Week",
      value: "lastweek",
    },
    {
      label: "This Month",
      value: "thismonth",
    },
    {
      label: "Last Month",
      value: "lastmonth",
    },
    {
      label: "This Year",
      value: "thisyear",
    },
    {
      label: "Last Year",
      value: "lastyear",
    },
    {
      label: "Custom",
      value: "custom",
    },
  ];

  const generateQuery = (dateFilter, setPage) => {
    const queryParams = [];
    if (dateFilter) {
      if (dateFilter === "custom") {
        setOpen(true);
      } else {
        queryParams.push(`dateFilter=${dateFilter}`);
        setPage(1);
      }
    }
    return queryParams.join("&");
  };

  useEffect(() => {
    const query = generateQuery(dateFilter, setPage);
    setQuery(`${query}&page=${page}&limit=${limit}`);
    setDownloadQuery(query)
  }, [page, limit, setQuery, dateFilter, setPage]);

  const clear = () => {
    setStartDate("");
    setEndDate("");
    handedClear();
    setDateFilter(null);
    setOpen(false);
    setDownloadQuery("")
  };

  const handedClear = () => {
    setQuery(`page=${page}&limit=${limit}`);
  };

  const handleOrderDateFilter = () => {
    if (!startDate || !endDate) {
      return alert("please select date");
    }
    setQuery(`startDate=${startDate}&endDate=${endDate}`);
    setDownloadQuery(`startDate=${startDate}&endDate=${endDate}`)
  };

  return (
    <div className=" p-5">
      <div className="flex items-center md:flex-row flex-col gap-5">
        <div className=" md:w-[70%] w-full flex items-center gap-5">
          <div className=" w-[300px]">
            <div className=" w-full">
              <HeadingSelect
                value={dateFilter}
                setValue={setDateFilter}
                placeholder={"Select Customer"}
                options={options3}
              />
            </div>
          </div>

          {open ? (
            <div className="w-full flex  md:flex-row flex-col items-center gap-2">
              <div className=" w-full">
                <input
                  onChange={(e) => setStartDate(e.target.value)}
                  type={"date"}
                  value={startDate}
                  placeholder="mm/dd/yyy"
                  className="bg-white py-2.5 h-[45px] px-4 text-gray-700 outline-none rounded-md border text-sm w-full border-gray-300"
                />
              </div>{" "}
              <span className="hidden md:block"> to</span>
              <div className=" w-full">
                <input
                  onChange={(e) => setEndDate(e.target.value)}
                  type={"date"}
                  value={endDate}
                  placeholder="mm/dd/yyy"
                  className="bg-white py-2.5 h-[45px] px-4 text-gray-700 outline-none rounded-md border text-sm w-full border-gray-300"
                />
              </div>
              <button
                onClick={() => handleOrderDateFilter()}
                className="h-[45px] bg-primary flex w-full md:w-auto cursor-pointer items-center justify-center text-xl px-3 rounded-md  text-white hover:btn-info"
              >
                <IoSearch />
              </button>
            </div>
          ) : (
            <></>
          )}

          {dateFilter || startDate || endDate ? (
            <button
              onClick={() => clear()}
              className=" px-5 h-[45px]   py-2 bg-red-500 rounded-md text-white"
            >
              {" "}
              Clear
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className=" w-[30%]">
          <ExpenseDownload query={downloadQuery} />
        </div>
      </div>
      <div className=" flex items-center gap-3 justify-between pt-4">
        <h2 className=" font-normal text-base">
          Total Expense :{" "}
          <span className=" font-semibold">৳ {totalAmount}</span>
        </h2>
      </div>
    </div>
  );
};

export default ExpenseReporteFilter;

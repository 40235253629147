import React, { useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import CustomTable from "../../components/shared/table/CustomTable";
import { Pagination } from "antd";
import BreadCrumb from "../../components/shared/BreadCrumb";
import HistoryAction from "../../components/allHistory/HistoryAction";
import HistoryFilter from "../../components/allHistory/HistoryFilter";
import { useGetHistoryQuery } from "../../redux/features/history/historyApi";
import { formatDate } from "../../utils/commonFun";

const AllHistory = () => {
  const [querySearch, setQuerySearch] = useState("");
  const [page, setPage] = useState(1);
  const limit = 10;

  console.log(querySearch);

  const { data, isLoading, refetch } = useGetHistoryQuery(querySearch, {
    refetchOnMountOrArgChange: true,
  });

  const totalItems = data?.item;

  const columns = [
    {
      title: "Line Id",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.profile?.line_id}
        </span>
      ),
      width: "100px",
    },
    {
      title: "Customer Name",
      key: "id",
      render: (row) => (
        <div className=" flex flex-col">
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.profile?.name}
          </span>
        </div>
      ),
      width: "150px",
    },
    {
      title: "Amount",
      render: (row) => (
        <div className="flex flex-col text-info">
          <span className="text-[14px] font-medium">
            <span className=" text-base">৳</span> {row?.payment}
          </span>
        </div>
      ),
    },
    {
      title: " Method",
      render: (row) => (
        <div className="flex flex-col text-info">
        <span className="text-[14px] font-medium">{row?.method}</span>
      </div>
      ),
    },
    {
      title: "Month",
      render: (row) => (
        <div className=" flex items-start flex-col gap-1">
          {row?.due_months?.map((item, index) => (
            <span
              key={index}
              className=" text-[14px] capitalize font-normal text-info"
            >
              {item}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "Payment Date",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {formatDate(row?.createdAt)}
        </span>
      ),
    },
    {
      title: "Note",
      render: (row) => (
        <div className="flex flex-col text-info">
          <span className="text-[14px] font-medium">{row?.note}</span>
        </div>
      ),
      width: "200px",
    },
    {
      title: "Action",
      render: (product) => (
        <>
          <HistoryAction row={product} refetch={refetch} />
        </>
      ),
    },
  ];

  const PaginationChange = (page, pageSiz) => {
    setPage(page);
  };

  return (
    <div className=" pb-8">
      <BreadCrumb
        title={"Payment History"}
        links={[
          { title: "Admins", url: "/admin/dashboard" },
          { title: "Payment History", url: "#" },
        ]}
      />

      {isLoading ? (
        <>
          <div className=" flex items-center justify-center w-full h-[80vh]">
            <ImSpinner2 className=" animate-spin w-12 h-12" />
          </div>
        </>
      ) : (
        <div className=" bg-white shadow-sm mt-5 rounded-md">
          <HistoryFilter
            setQuery={setQuerySearch}
            page={page}
            setPage={setPage}
            limit={limit}
            refetch={refetch}
            query={querySearch}
            totalAmount = {data?.totalAmount}
          />
          <CustomTable columns={columns} data={data?.data} />
          <div className=" py-4 flex items-center justify-between px-4">
            <div className="text-light-black font-medium text-[14px] text-[#68769F]">
              {`Showing ${page * limit - limit + 1} - ${Math.min(
                page * limit,
                totalItems
              )} of ${totalItems}`}
            </div>
            <Pagination
              defaultCurrent={page}
              total={totalItems}
              pageSize={limit}
              onChange={PaginationChange}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AllHistory;

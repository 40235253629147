import React from "react";
import { saveAs } from "file-saver";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

const DownloadHistory = ({query}) => {
  const downloadExcel = async () => {
    try {
      const response = await fetch(
        `https://anondo-avation-server.vercel.app/api/v1/history/download?${query}`,
        {
          method: "GET",
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, "Payment.xlsx");
      } else {
        console.error("Failed to download file");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const downloadPDF = async () => {
    try {
      const response = await axios.get(`https://anondo-avation-server.vercel.app/api/v1/history?${query}`);
      const doc = new jsPDF();
      doc.text("Payment List", 14, 16);

      const tableColumn = [
        "Line Id",
        "Customer Name",
        "Amount",
        "Method",
        "Month",
        "Payment Date",
        "note",
      ];

      const tableRows = [];
      let totalPayment = 0;

      response?.data?.data?.forEach((order) => {
        const paymentAmount = order?.payment || 0;
        const orderData = [
          order?.profile?.line_id,
          order?.profile?.name,
          paymentAmount,
          order?.method,
          order?.due_months?.join(", "),
          new Date(order?.createdAt).toLocaleDateString("en-CA"),
          order?.note,
        ];

        tableRows.push(orderData);
        totalPayment += paymentAmount; // Add to total payment
      });

      // Generate the table in the PDF
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20,
      });

      doc.autoTable({
        body: [
          ["", "", "", "", "", `Total Payment: ${totalPayment.toFixed(2)}`],
        ],
        startY: doc.autoTable.previous.finalY + 10, // Start below the previous table
        theme: "plain", // No borders or styling for this summary row
        styles: { halign: "center", fontStyle: "bold" }, // Align text to center
      });
      doc.save("payment.pdf");
    } catch (error) {
      console.error("Error downloading the PDF", error);
    }
  };

  const printPDF = async () => {
    try {
      const response = await axios.get(`https://anondo-avation-server.vercel.app/api/v1/history?${query}`);
      const doc = new jsPDF();
      doc.text("Payment List", 14, 16);

      const tableColumn = [
        "Line Id",
        "Customer Name",
        "Amount",
        "Method",
        "Month",
        "Payment Date",
        "note",
      ];
      const tableRows = [];
      let totalPayment = 0;

      response?.data?.data?.forEach((order) => {
        const paymentAmount = order?.payment || 0;
        const orderData = [
          order?.profile?.line_id,
          order?.profile?.name,
          paymentAmount,
          order?.method,
          order?.due_months?.join(", "),
          new Date(order?.createdAt).toLocaleDateString("en-CA"),
          order?.note,
        ];

        tableRows.push(orderData);
        totalPayment += paymentAmount; // Add to total payment
      });

      // Generate the table in the PDF
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20,
      });

      doc.autoTable({
        body: [
          ["", "", "", "", "", `Total Payment: ${totalPayment.toFixed(2)}`],
        ],
        startY: doc.autoTable.previous.finalY + 10, // Start below the previous table
        theme: "plain", // No borders or styling for this summary row
        styles: { halign: "center", fontStyle: "bold" }, // Align text to center
      });
      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = pdfUrl;

      document.body.appendChild(iframe);
      iframe.onload = function () {
        iframe.contentWindow.print();

        iframe.contentWindow.onafterprint = function () {
          document.body.removeChild(iframe);
        };
      };
    } catch (error) {
      console.error("Error downloading the PDF", error);
    }
  };

  return (
    <div className=" flex items-center gap-2 justify-end">
      <button
        onClick={downloadExcel}
        className=" py-2 px-3 rounded-md bg-green-500 text-white hover:bg-green-600 duration-300"
      >
        Excel
      </button>
      <button
        onClick={downloadPDF}
        className=" py-2 px-3 rounded-md bg-yellow-500 text-white hover:bg-yellow-600 duration-300"
      >
        Pdf
      </button>
      <button
        onClick={printPDF}
        className=" py-2 px-3 rounded-md bg-blue-500 text-white hover:bg-blue-600 duration-300"
      >
        Print
      </button>
    </div>
  );
};

export default DownloadHistory;

import { Select } from "antd";
import React from "react";

const HeadingSelect = ({
  value,
  setValue,
  placeholder,
  options,
}) => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setValue(value);
  };

  return (
    <div className="flex flex-col items-start w-full ">
      <Select
        id="custom-select"
        showSearch
        optionFilterProp="label"
        value={value}
        // filterSort={(optionA, optionB) =>
        //   (optionA?.label ?? "")
        //     .toLowerCase()
        //     .localeCompare((optionB?.label ?? "").toLowerCase())
        // }
        placeholder={placeholder}
        className="custom-select-class2 h-[45px] font-normal text-block  rounded-md w-full text-sm outline-none border-[1px] focus:border-primary focus:bg-transparent"
        options={options}
        onChange={handleChange}
      />
    </div>
  );
};

export default HeadingSelect;





export const formatDate = (date) => {
    const currentDate = new Date(date);
    return currentDate.toLocaleDateString("en-US", {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import SearchField from "../shared/input/SearchField";
import HeadingSelect from "../shared/input/HeadingSelect";
import { useGetSectorsQuery } from "../../redux/features/sector/sectorApi";
import { IoSearch } from "react-icons/io5";

const PaymentDueFilter = ({ setQuery, page, limit, setPage, refetch }) => {
  const [category, setCategory] = useState(null);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState("");
  const [searchValue] = useDebounce(search, 1000);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { data } = useGetSectorsQuery("limit=100", {
    refetchOnMountOrArgChange: true,
  });

  const options = data?.data?.map((item) => {
    return { label: item?.title, value: item?._id };
  });

  const generateQuery = (searchValue, status) => {
    const queryParams = [];
    if (searchValue) {
      queryParams.push(`search=${searchValue}`);
      setPage(1);
    }

    if (status) {
      queryParams.push(`line_sector=${status}`);
      setPage(1);
    }
    return queryParams.join("&");
  };

  useEffect(() => {
    const query = generateQuery(searchValue, status);
    setQuery(`${query}&status=due&sort=last_pay_date&page=${page}&limit=${limit}`);
    // refetch();
  }, [searchValue, status, page, limit, setQuery]);

  const clear = () => {
    setSearch("");
    setStatus(null);
    setCategory(null);
    setStartDate("");
    setEndDate("");
    handedClear()
  };


  const  handedClear = ()=> {
    setQuery(`status=due&page=${page}&limit=${limit}`);
  }

  const handleOrderDateFilter = () => {
    if (!startDate || !endDate) {
      return alert("please select date");
    }
    setQuery(`status=due&startDate=${startDate}&endDate=${endDate}`);
  };

  return (
    <div className=" p-5">
      <div className="flex items-center md:flex-row flex-col gap-5">
        <div className="w-full">
          <SearchField
            value={search}
            setValue={setSearch}
            placeholder="Search ..."
          />
        </div>
        <HeadingSelect
          value={status}
          setValue={setStatus}
          placeholder={"Select Line Sector"}
          options={options}
        />

        <div className="w-full flex md:flex-row flex-col items-center gap-2">
          <div className=" w-full">
            <input
              onChange={(e) => setStartDate(e.target.value)}
              type={"date"}
              value={startDate}
              placeholder="mm/dd/yyy"
              className="bg-white py-2.5 h-[45px] px-4 text-gray-700 outline-none rounded-md border text-sm w-full border-gray-300"
            />
          </div>{" "}
          <span className="hidden md:block"> to</span>
          <div className=" w-full">
            <input
              onChange={(e) => setEndDate(e.target.value)}
              type={"date"}
              value={endDate}
              placeholder="mm/dd/yyy"
              className="bg-white py-2.5 h-[45px] px-4 text-gray-700 outline-none rounded-md border text-sm w-full border-gray-300"
            />
          </div>
          <button
            onClick={() => handleOrderDateFilter()}
            className="h-[45px] bg-primary flex cursor-pointer items-center justify-center text-xl px-3 rounded-md  text-white hover:btn-info"
          >
            <IoSearch />
          </button>
        </div>

        {search || status || category || startDate || endDate ? (
          <button
            onClick={() => clear()}
            className=" px-5 h-[45px]   py-2 bg-red-500 rounded-md text-white"
          >
            {" "}
            Clear
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PaymentDueFilter;

import React, { useEffect, useState } from "react";
import CustomModal from "../shared/modal/CustomModal";
import { useForm } from "react-hook-form";
import CustomInput from "../shared/input/CustomInput";
import toast from "react-hot-toast";
import CustomTextArea from "../shared/input/CustomTextArea";
import { useGetHeadQuery } from "../../redux/features/head/headApi";
import CustomNumberInput2 from "../shared/input/CustomNumberInput2";
import CustomSelect from "../shared/input/CustomSelect";
import { useEditExpenseMutation } from "../../redux/features/expense/expenseApi";

const UpdateExpense = ({ open, setOpen, refetch, row }) => {
  const [agency, setAgency] = useState(null);
  const [cost, setCost] = useState();
  const [joinDate, setJoinDate] = useState(null);
  const { data } = useGetHeadQuery("type=expense", {
    refetchOnMountOrArgChange: true,
  });

  const options = data?.data?.map((item) => {
    return { label: item?.title, value: item?._id };
  });

  const [editExpense, { isLoading, isSuccess, error }] = useEditExpenseMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm();

  const handelCost = (str) => {
    const input = str?.replace(/\D/g, ""); // Remove all non-numeric characters
    const formatted = formatNumberWithCommas(input);
    setCost(formatted);
  };

  const onlyNumber = (str) => {
    const result = str?.match(/\d+/g)?.join("") || "";
    return Number(result);
  };

  const formatNumberWithCommas = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    if (row) {
      setJoinDate(new Date(row?.date).toISOString().split("T")[0]);
      setValue("name", row?.name);
      setValue("invoice_number", row?.invoice_number);
      setValue("description", row?.description);
      setCost(row?.amount?.toString());
      setAgency(row?.head?._id);
    }
  }, [row, setValue]);

  useEffect(() => {
    if (isSuccess) {
      const message = "Income Update successfully";
      toast.success(message);
      refetch()
      setOpen(false)
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error,refetch,setOpen]);

  const onSubmit = async (values) => {
    const data = {
      name: values?.name,
      head: agency,
      invoice_number: values?.invoice_number,
      date: new Date(joinDate),
      description: values?.description,
      amount: onlyNumber(cost),
    };
    const id = row?._id;
    await editExpense({id,data});
  };

  return (
    <CustomModal open={open} setOpen={setOpen} width={700} title="Update Expense">
      <div className=" max-h-[80vh] overflow-y-auto">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full h-full mt-[0px]"
        >
          <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
            <CustomInput
              label={"Name"}
              type={"text"}
              register={register("name", {
                required: {
                  value: true,
                  message: "Name is required",
                },
              })}
              error={errors.name}
              placeholder={"Enter name"}
            />
            <CustomSelect
              label={"Expense Head"}
              value={agency}
              setValue={setAgency}
              options={options}
              required={true}
              placeholder={"Select Income Head"}
            />
          </div>

          <div className=" flex items-start justify-between mt-1 gap-5 md:flex-row flex-col">
            <CustomInput
              label={"Invoice Number"}
              type={"text"}
              register={register("invoice_number", {
                required: {
                  value: true,
                  message: "Invoice Number is required",
                },
              })}
              error={errors.invoice_number}
              placeholder={"Enter Invoice Number"}
            />
            <div className="flex flex-col items-start w-full mt-3">
              <label
                htmlFor="otp"
                className="mb-1.5 font-medium text-sm text-dark-gray"
              >
                Date
              </label>
              <input
                className="py-[15px] h-[45px] px-[14px] font-normal  text-dark-gray rounded-md   w-full text-sm outline-none  border-[1px] focus:border-primary bg-gray-100 focus:bg-transparent"
                type={"date"}
                id="otp"
                value={joinDate}
                onChange={(e) => setJoinDate(e.target.value)}
              />
            </div>
          </div>

          <div className=" mt-1">
            <CustomTextArea
              label={"Description"}
              type={"text"}
              register={register("description", {
                required: {
                  value: true,
                  message: "description is required",
                },
              })}
              error={errors.description}
              placeholder={"Enter description"}
            />
          </div>

          <div className=" flex items-start justify-between mt-1 md:flex-row flex-col gap-5">
            <CustomNumberInput2
              label="Amount (BDT) "
              name="amount"
              type="text"
              placeholder="Enter Amount (BDT)"
              register={register}
              value={cost}
              validation={{
                required: true,
                pattern: {
                  value: /^[0-9,]+$/,
                  message: "Total Amount consist of digits only.",
                },
              }}
              onChange={(e) => handelCost(e.target.value)}
              trigger={trigger}
              errors={errors}
            />
            <div className="flex flex-col items-start w-full mt-3">
            </div>
          </div>
          <div className=" mt-5">
            <button
              type="submit"
              className=" py-2 text-sm font-medium px-7 bg-primary text-white rounded-md"
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default UpdateExpense;

import React, { useState } from "react";
import DuePaymentModal from "./DuePaymentModal";

const DuePaymentAction = ({ row, refetch }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="flex items-center gap-2">
        <button
          onClick={() => setOpen(true)}
          className=" bg-green-500 text-white py-1 px-2 rounded-md text-sm font-medium"
        >
          Pay
        </button>
      </div>
      <DuePaymentModal
        row={row}
        open={open}
        setOpen={setOpen}
        refetch={refetch}
      />
    </>
  );
};

export default DuePaymentAction;

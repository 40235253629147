import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../shared/modal/DeleteModal";
import toast from "react-hot-toast";
import { useDeleteHistoryMutation } from "../../redux/features/history/historyApi";
import { Link } from "react-router-dom";
import { TbFileInvoice } from "react-icons/tb";

const HistoryAction = ({ row, refetch }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteHistory, { isLoading, error, isSuccess }] =
    useDeleteHistoryMutation();

  useEffect(() => {
    if (isSuccess) {
      const message = "Profile delete successfully";
      toast.success(message);
      refetch();
      setDeleteModal(false);
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error, refetch]);

  const handelDelete = async () => {
    await deleteHistory(row?._id);
  };

  return (
    <>
      <div className="flex items-center gap-1">
        <Link to={`/admin/invoice/${row?._id}`}   className=" text-xl hover:text-green-500 duration-200">
          <TbFileInvoice />
        </Link>
        <button
          onClick={() => setDeleteModal(true)}
          className=" text-xl hover:text-red-500 duration-200"
        >
          <RiDeleteBin6Line />
        </button>
      </div>

      <DeleteModal
        modalOPen={deleteModal}
        onDelete={() => handelDelete()}
        setModalOpen={setDeleteModal}
        title={"Delete History!"}
        title2={
          "Are you sure you want to delete this History? This action cannot be undone."
        }
        isLoading={isLoading}
      />
    </>
  );
};

export default HistoryAction;

import React, { useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import CustomTable from "../../components/shared/table/CustomTable";
import { Pagination } from "antd";
import BreadCrumb from "../../components/shared/BreadCrumb";
import SalesFilter from "../../components/sales/SalesFilter";
import SaleAction from "../../components/sales/SaleAction";
import { useGetProfileQuery } from "../../redux/features/profile/profileApi";
import { formatDate } from "../../utils/commonFun";

const SalesList = () => {
  const [querySearch, setQuerySearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data, isLoading, refetch } = useGetProfileQuery(querySearch, {
    refetchOnMountOrArgChange: true,
  });

  console.log(data);

  const totalItems = data?.item;

  const columns = [
    {
      title: "Line Id",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.line_id}
        </span>
      ),
    },
    {
      title: "Customer Name",
      key: "id",
      render: (row) => (
        <div className=" flex flex-col">
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.name}
          </span>
        </div>
      ),
      width: "150px",
    },

    {
      title: "Phone",
      key: "id",
      render: (row) => (
        <div className=" flex flex-col">
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.phone}
          </span>
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.other_phone}
          </span>
        </div>
      ),
    },
    {
      title: "Line Sector",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.line_sector?.title}
        </span>
      ),
    },
    {
      title: "Join Date",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {formatDate(row?.join_date)}
        </span>
      ),
    },
    {
      title: "Bill Remainder",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {formatDate(row?.bill_remainder_date)}
        </span>
      ),
    },
    {
      title: "Address",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.address}
        </span>
      ),
    },
    {
      title: "Line Charge",
      render: (product) => (
        <div className="flex flex-col text-info">
          <span className="text-[14px] font-medium">
            <span className=" text-base">৳</span> {product.line_charge}
          </span>
        </div>
      ),
    },

    {
      title: "Actions",
      render: (row) => (
        <div className="flex items-center gap-1">
          <SaleAction row={row} refetch={refetch} />
        </div>
      ),
      width: "100px",
    },
  ];

  const PaginationChange = (page, pageSiz) => {
    setPage(page);
  };

  return (
    <div className=" pb-8">
      <BreadCrumb
        title={"Customer Profile List"}
        links={[
          { title: "Admins", url: "/admin/dashboard" },
          { title: "Profile List", url: "#" },
        ]}
      />

      {isLoading ? (
        <>
          <div className=" flex items-center justify-center w-full h-[80vh]">
            <ImSpinner2 className=" animate-spin w-12 h-12" />
          </div>
        </>
      ) : (
        <div className=" bg-white shadow-sm mt-5 rounded-md">
          <SalesFilter
            setQuery={setQuerySearch}
            page={page}
            setPage={setPage}
            limit={limit}
            refetch={refetch}
          />
          <CustomTable columns={columns} data={data?.data} />
          <div className=" py-4 flex items-center justify-between px-4">
            <div className="text-light-black font-medium text-[14px] text-[#68769F]">
              {`Showing ${page * limit - limit + 1} - ${Math.min(
                page * limit,
                totalItems
              )} of ${totalItems}`}
            </div>
            <Pagination
              defaultCurrent={page}
              total={totalItems}
              pageSize={limit}
              onChange={PaginationChange}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SalesList;

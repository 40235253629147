import React, { useEffect } from "react";
import CustomModal from "../shared/modal/CustomModal";
import { useForm } from "react-hook-form";
import CustomInput from "../shared/input/CustomInput";
import toast from "react-hot-toast";
import { useCreateSectorMutation } from "../../redux/features/sector/sectorApi";
import CustomTextArea from "../shared/input/CustomTextArea";

const AgentAdd = ({ open, setOpen, refetch }) => {
  const [createSector, { error, isSuccess, isLoading }] =
    useCreateSectorMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (isSuccess) {
      const message = "Line sector add successfully";
      toast.success(message);
      reset();
      setOpen(false);
      refetch();
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error,reset,refetch,setOpen]);

  const onSubmit = async (values) => {
    const data = {
      title: values?.title,
      area: values?.area,
    };

    await createSector(data);
  };

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      width={600}
      title="Add Line Sector"
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
          <div className=" flex items-start justify-between gap-1 flex-col">
            <CustomInput
              label={"Line Sector Title"}
              type={"text"}
              register={register("title", {
                required: {
                  value: true,
                  message: "Title is required",
                },
              })}
              error={errors.passenger_name}
              placeholder={"Line Sector Title"}
            />
            <CustomTextArea
              label={"Line Sector Area"}
              type={"text"}
              register={register("area", {
                required: {
                  value: true,
                  message: "Line Sector Area is required",
                },
              })}
              error={errors.passenger_name}
              placeholder={"Line Sector Area"}
            />
          </div>

          <div className=" mt-7 mb-3 flex items-center justify-center">
            <button
              type="submit"
              className=" py-2 text-sm font-medium px-7 bg-primary text-white rounded-md"
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default AgentAdd;


import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import SearchField from "../shared/input/SearchField";
import HeadingSelect from "../shared/input/HeadingSelect";

const AdminListFilter = ({ setQuery, page, limit, setPage, refetch }) => {
  const [category, setCategory] = useState(null);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState("");
  const [searchValue] = useDebounce(search, 1000);

  const shortBy = [
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Staff",
      value: "staff",
    },
  ];



  const generateQuery = () => {
    const queryParams = [];

    // if (category) {
    //   queryParams.push(`category=${category}`);
    //   setPage(1);
    // }

    if (searchValue) {
      queryParams.push(`search=${searchValue}`);
      setPage(1);
    }

    // if (status) {
    //   queryParams.push(`status=${status}`);
    //   setPage(1);
    // }
    return queryParams.join("&");
  };

  useEffect(() => {
    const query = generateQuery();
    setQuery(`${query}&page=${page}&limit=${limit}`);
    // refetch();
  }, [searchValue, page, limit]);

  const clear = () => {
    setSearch("");
    setStatus(null);
    setCategory(null);
  };

  return (
    <div className=" p-5">
      <div className="flex items-center gap-5">
        <div className="w-[50%]">
          <SearchField
            value={search}
            setValue={setSearch}
            placeholder="Search Name..."
          />
        </div>
        <div className=" flex w-[50%] items-center gap-5">
          {/* <HeadingSelect
            value={category}
            setValue={setCategory}
            placeholder={"Category"}
            options={options}
          /> */}
          <HeadingSelect
            value={status}
            setValue={setStatus}
            placeholder={"Admin role"}
            options={shortBy}
          />

          {search || status || category ? (
            <button
              onClick={() => clear()}
              className=" px-5 h-[45px]   py-2 bg-red-500 rounded-md text-white"
            >
              {" "}
              Clear
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminListFilter;

import { useState } from "react";
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";

const SubMenu = ({ data }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const path = useLocation();

  const pathname = path.pathname;

  const isActive = (href) => {
    return pathname.slice(0, data?.active) === href;
  };

  const isActive2 = (href) => {
    return pathname === href;
  };

  return (
    <>
      <li className={`w-full`} onClick={() => setSubMenuOpen((pre) => !pre)}>
        <div
          className={`flex items-center text-sm w-full cursor-pointer py-3 relative group rounded-lg  font-semibold px-5 gap-2 ${
            isActive(data?.activeData)
              ? " bg-primary text-white"
              : "text-slate-500 hover:bg-gray-200"
          }`}
        >
          <data.icon size={16} className="min-w-max" />
          <p className="flex-1 capitalize">{data.name}</p>
          <IoIosArrowDown
            className={` ${subMenuOpen && "rotate-180"} duration-200 `}
          />
        </div>
      </li>
      <motion.ul
        animate={
          subMenuOpen
            ? {
                height: "fit-content",
              }
            : {
                height: 0,
              }
        }
        className="flex h-0 flex-col pl-3 rounded-md font-normal overflow-hidden"
      >
        {data.menus?.map((menu) => (
          <li
            key={menu}
            onClick={() => {
              setSubMenuOpen(true);
            }}
            className=""
          >
            <Link
              to={menu?.link ? menu?.link : "/"}
              className={`flex items-center text-sm py-3 group relative group rounded-lg  px-5 gap-2 hover:bg-success/10 mb-1 duration-200 ${
                isActive2(menu?.link)
                  ? " font-semibold bg-success/10"
                  : " text-slate-500"
              }`}
            >
              <div
                className={` ${
                  isActive2(menu?.link) ? " w-[6px] h-[6px] rounded-full bg-gray-500 " : "text-gray-500 w-[6px] h-[6px] rounded-full bg-gray-300  "
                }`}
              >
                
              </div>
              {menu?.title}
            </Link>
          </li>
        ))}
      </motion.ul>
    </>
  );
};

export default SubMenu;

import React, { useEffect } from "react";
import CustomModal from "../shared/modal/CustomModal";
import { useForm } from "react-hook-form";
import CustomInput from "../shared/input/CustomInput";
import toast from "react-hot-toast";
import { useEditProfileMutation } from "../../redux/features/profile/profileApi";

const DuePaymentDateChange = ({ row, open, setOpen, refetch }) => {
    const [editProfile, { isLoading, isSuccess, error }] =
    useEditProfileMutation();


    console.log(error)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (isSuccess) {
      const message = "payment successfully";
      toast.success(message);
      refetch();
      setOpen(false);
      reset();
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error, refetch]);

  const convertISOToDateInputFormat = (isoDateString) => {
    return new Date(isoDateString).toISOString().split("T")[0];
  };

  useEffect(() => {
    if (row) {
      if (row?.last_pay_date) {
        const formattedDate = new Date(row?.last_pay_date)
          .toISOString()
          .slice(0, 10); // Cut down the string to remove seconds and timezone
        setValue("last_pay_date", formattedDate);
      }
    }
  }, [row, setValue]);

  const onSubmit = async (values) => {
    const data = {
        last_pay_date: values?.last_pay_date,
    };

    const id = row?._id
    await editProfile({id,data});
  };



  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      width={500}
      title="Expire Date Change Model"
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
          <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
            <CustomInput
              label={"Payment Date"}
              type={"date"}
              readOnly={false}
              register={register("last_pay_date", {
                required: {
                  value: false,
                  message: "Date is required",
                },
              })}
              error={errors.last_pay_date}
              placeholder={"Line Sector Title"}
            />
          </div>

          <div className=" mt-7 mb-3 flex items-center justify-center">
            <button
              type="submit"
              className=" py-2 text-sm font-medium px-7 bg-primary text-white rounded-md"
            >
              {isLoading ? "Loading..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default DuePaymentDateChange;

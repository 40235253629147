import { apiSlice } from "../api/apiSlice";


export const historyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createHistory: builder.mutation({
      query: (data) => ({
        url: "history/create",
        method: "POST",
        body: data,
      }),
    }),
    getHistory: builder.query({
      query: (query) => ({
        url: `history?${query}`,
        method: "GET",
      }),
    }),
    getSingleHistory: builder.query({
      query: (id) => ({
        url: `history/${id}`,
        method: "GET",
      }),
    }),
    editHistory: builder.mutation({
      query: ({ id, data }) => ({
        url: `history/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteHistory: builder.mutation({
      query: (id) => ({
        url: `history/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
useCreateHistoryMutation,
useGetHistoryQuery,
useDeleteHistoryMutation,
useGetSingleHistoryQuery,
} = historyApi;

import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/shared/BreadCrumb";
import { useForm } from "react-hook-form";
import CustomInput from "../../components/shared/input/CustomInput";
import CustomNumberInput from "../../components/shared/input/CustomNumberInput";
import CustomSelect from "../../components/shared/input/CustomSelect";
import toast from "react-hot-toast";
import CustomNumberInput2 from "../../components/shared/input/CustomNumberInput2";
import { useGetSectorsQuery } from "../../redux/features/sector/sectorApi";
import {
  useEditProfileMutation,
  useGetSingleProfileQuery,
} from "../../redux/features/profile/profileApi";
import { useParams } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";

const ProfileEdit = () => {
  const { id } = useParams();
  const [agency, setAgency] = useState(null);
  const [cost, setCost] = useState();
  const [joinDate, setJoinDate] = useState(null);
  const { data } = useGetSectorsQuery("limit=100", {
    refetchOnMountOrArgChange: true,
  });

  const options = data?.data?.map((item) => {
    return { label: item?.title, value: item?._id };
  });

  const {
    data: profileData,
    isLoading: loading,
    refetch,
  } = useGetSingleProfileQuery(id, { refetchOnMountOrArgChange: true });

  const [editProfile, { isLoading, isSuccess, error }] =
  useEditProfileMutation();
  console.log(profileData?.profile);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm();

  useEffect(() => {
    if (profileData?.profile) {
      setValue("name", profileData?.profile?.name);
      setValue("phone", profileData?.profile?.phone);
      setValue("other_phone", profileData?.profile?.other_phone);
      setValue("line_id", profileData?.profile?.line_id);
  
      // Format the date correctly for datetime-local input
      if (profileData?.profile?.bill_remainder_date) {
        const formattedDate = new Date(profileData?.profile?.bill_remainder_date)
          .toISOString()
          .slice(0, 10); // Cut down the string to remove seconds and timezone
        setValue("bill_remainder_date", formattedDate);
      }
  
      setValue("address", profileData?.profile?.address);
  
      // Handle join date similarly
      if (profileData?.profile?.join_date) {
        const formattedJoinDate = new Date(profileData?.profile?.join_date)
          .toISOString()
          .slice(0, 10); // Same formatting for datetime-local
        setJoinDate(formattedJoinDate);
      }
  
      setAgency(profileData?.profile?.line_sector);
      setCost(profileData?.profile?.line_charge?.toString());
    }
  }, [setValue, profileData]);
  
  

  const handelCost = (str) => {
    const input = str?.replace(/\D/g, "");
    const formatted = formatNumberWithCommas(input);
    setCost(formatted);
  };

  const onlyNumber = (str) => {
    const result = str?.match(/\d+/g)?.join("") || "";
    return Number(result);
  };

  const formatNumberWithCommas = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  //   useEffect(() => {
  //     setJoinDate(new Date().toISOString().split("T")[0]);
  //   }, [setValue]);

  //   useEffect(() => {
  //     if (joinDate) {
  //       const date = new Date(joinDate);
  //       date.setMonth(date.getMonth() + 1);
  //       setValue("bill_remainder_date", date.toISOString().split("T")[0]);
  //     }
  //   }, [setValue, joinDate]);

  useEffect(() => {
    if (isSuccess) {
      const message = "Profile update successfully";
      toast.success(message);
      refetch()
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error,refetch]);

  const onSubmit = async (values) => {
    const data = {
      name: values?.name,
      phone: values?.phone,
      other_phone: values?.other_phone,
      line_id: values?.line_id,
      join_date: joinDate,
      bill_remainder_date: values?.bill_remainder_date,
      line_sector: agency,
      address: values?.address,
      line_charge: onlyNumber(cost),
      // status:"due",
      // due_months:["jun","august"],
      // amount_due:onlyNumber(cost) + onlyNumber(cost),
      // last_pay_date:new Date(joinDate),
    };
    await editProfile({id,data});
  };

  if (loading) {
    return (
      <div className=" flex items-center justify-center w-full h-[80vh]">
        <ImSpinner2 className=" animate-spin w-12 h-12" />
      </div>
    );
  }

  return (
    <div>
      <BreadCrumb
        title="Update Customer Profile"
        links={[
          { title: "Admins", url: "/admin/dashboard" },
          { title: "Update Customer", url: "#" },
        ]}
      />

      <div className=" bg-white shadow-md rounded-lg p-5 mb-7">
        <h2 className=" font-semibold text-lg">Update Customer Profile</h2>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full h-full mt-[0px]"
          >
            <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
              <CustomInput
                label={"Full Name"}
                type={"text"}
                register={register("name", {
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                })}
                error={errors.name}
                placeholder={"Enter Full name"}
              />
              <CustomInput
                label={"Line Id"}
                type={"text"}
                register={register("line_id", {
                  required: {
                    value: true,
                    message: "line id is required",
                  },
                })}
                error={errors.line_id}
                placeholder={"Enter Line Id"}
              />
            </div>

            <div className=" flex items-start justify-between gap-5 mt-3 md:flex-row flex-col">
              <CustomNumberInput
                label="Phone Number"
                name="phone"
                type="text"
                placeholder="Enter Phone Number"
                register={register}
                validation={{
                  required: "Phone is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Phone must consist of digits only.",
                  },
                }}
                trigger={trigger}
                errors={errors}
              />
              <CustomNumberInput
                label="Another Phone Number"
                name="other_phone"
                type="text"
                placeholder="Enter Another Phone Number"
                register={register}
                validation={{
                  required: false,
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Phone must consist of digits only.",
                  },
                }}
                trigger={trigger}
                errors={errors}
              />
            </div>

            <div className=" flex items-start justify-between mt-3 gap-5 md:flex-row flex-col">
              <CustomSelect
                label={"Line Sector"}
                value={agency}
                setValue={setAgency}
                options={options}
                required={true}
                placeholder={"Select Line Sector"}
              />
              <CustomInput
                label={"Address"}
                type={"text"}
                register={register("address", {
                  required: {
                    value: true,
                    message: "Address is required",
                  },
                })}
                error={errors.address}
                placeholder={"Enter Address"}
              />
            </div>

            <div className=" flex items-start justify-between mt-3 md:flex-row flex-col gap-5">
              <div className="flex flex-col items-start w-full mt-3">
                <label
                  htmlFor="otp"
                  className="mb-1.5 font-medium text-sm text-dark-gray"
                >
                  Join Date
                </label>
                <input
                  className="py-[15px] h-[45px] px-[14px] font-normal  text-dark-gray rounded-md   w-full text-sm outline-none  border-[1px] focus:border-primary bg-gray-100 focus:bg-transparent"
                  type={"date"}
                  id="otp"
                  value={joinDate}
                  onChange={(e) => setJoinDate(e.target.value)}
                />
              </div>

              <CustomInput
                label={"Bill Remainder Date"}
                type={"date"}
                register={register("bill_remainder_date", {
                  required: {
                    value: true,
                    message: "bill remainder date  date is required",
                  },
                })}
                error={errors.bill_remainder_date}
                placeholder={"Enter Passenger Name"}
              />

              <CustomNumberInput2
                label="Line Charge"
                name="line_charge"
                type="text"
                placeholder="Enter Line Charge"
                register={register}
                value={cost}
                validation={{
                  required: false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: "Total Amount consist of digits only.",
                  },
                }}
                onChange={(e) => handelCost(e.target.value)}
                trigger={trigger}
                errors={errors}
              />
            </div>
            <div className=" mt-5">
              <button
                type="submit"
                className=" py-2 text-sm font-medium px-7 bg-primary text-white rounded-md"
              >
                {isLoading ? "Loading..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;

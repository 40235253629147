import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomInput from "../../components/shared/input/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import { useForgetChangeMutation } from "../../redux/features/auth/authApi";
import toast from "react-hot-toast";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [forgetChange, { isSuccess, isLoading, error, data }] = useForgetChangeMutation();

  const tokenString = localStorage.getItem("token_doctor");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (tokenString) {
      navigate("/admin/dashboard");
    }
  }, [tokenString,navigate]);

  useEffect(() => {
    if (isSuccess) {
      const message = "check your email inbox";
      toast.success(message);
      reset();
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error, data,reset,navigate]);

  const onSubmit = async (values) => {
    const { email } = values;

    const data = {
      email: email,
    };
    await forgetChange(data);
  };

  return (
    <div className=" flex items-center h-screen w-full justify-center">
      <div className="w-full px-8 py-10 md:w-[500px] bg-white rounded-lg shadow-md">
        <div className=" flex items-center justify-center">
          <img src="/image/logo.png" className=" w-[300px]" alt="logo" />
        </div>
        <div className="my-5 flex items-center justify-center flex-col">
          <h1 className="text-dark-gray text-[28px] font-bold">
            Forget Password
          </h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <CustomInput
              label={"Email"}
              type={"email"}
              register={register("email", {
                required: {
                  value: true,
                  message: "Please Enter User Email",
                },
              })}
              error={errors.lastName}
              placeholder={"Enter Email"}
            />
          </div>

          <div className="mt-6 w-full">
            <button className=" py-3 px-5 w-full bg-primary text-sm font-medium text-white rounded-md">
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>

          <p className=" w-full   mx-auto text-center mt-[23px]">
            Already have an account?{" "}
            <Link
              to="/"
              className="text-[#007AFF] font-bold cursor-pointer"
            >
              Please Login
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;

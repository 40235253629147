import { apiSlice } from "../api/apiSlice";


export const expenseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createExpense: builder.mutation({
      query: (data) => ({
        url: "expense/create",
        method: "POST",
        body: data,
      }),
    }),
    getExpense: builder.query({
      query: (query) => ({
        url: `expense?${query}`,
        method: "GET",
      }),
    }),
    getSingleExpense: builder.query({
      query: (id) => ({
        url: `expense/${id}`,
        method: "GET",
      }),
    }),
    editExpense: builder.mutation({
      query: ({ id, data }) => ({
        url: `expense/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteExpense: builder.mutation({
      query: (id) => ({
        url: `expense/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
    useCreateExpenseMutation,
    useGetExpenseQuery,
    useEditExpenseMutation,
    useDeleteExpenseMutation,
} = expenseApi;

import { Popover } from "antd";
import React, {useState } from "react";
import { IoMdMenu } from "react-icons/io";
import { LuLogOut } from "react-icons/lu";
import { useNavigate } from "react-router-dom";



function DashboardNav({setDrawerVisible}) {
  const [popupShow, setPopupShow] = useState(false);
  const navigate = useNavigate();


  const handleOpenChange = (newOpen) => {
    setPopupShow(newOpen);
  };

  const logoutFun = () => {
    localStorage.removeItem("token_doctor");
    localStorage.removeItem("role");
    navigate("/")

  };

  const content = (
    <div className=" w-[250px] p-0">
      <div>
        <ul className="flex items-start w-full flex-col gap-2">
          <button
            onClick={() => logoutFun()}
            className="text-slate-500 duration-300 hover:pl-7 font-semibold px-5 w-full flex items-center gap-2 text-sm py-3 rounded-lg  hover:bg-primary hover:text-white"
          >
            <LuLogOut size={16} className={" min-w-max"} />
            Logout
          </button>
        </ul>
      </div>
    </div>
  );

  return (
    <div className="w-full flex items-center justify-center h-[75px] bg-white border-b">
      <div className=" flex items-center w-full justify-between px-5 gap-2">

        <div>
            <button onClick={()=>setDrawerVisible((pre)=>!pre)} className=" text-3xl md:hidden"><IoMdMenu /></button>
        </div>

        <div className="flex justify-center items-center gap-3">
          <Popover
            open={popupShow}
            onOpenChange={handleOpenChange}
            content={content}
            placement="bottomRight"
            trigger="click"
          >
            <img
              src="/image/user.jpg"
              alt="user"
              className=" object-cover cursor-pointer w-[35px] h-[35px] rounded-full"
            />
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default DashboardNav;

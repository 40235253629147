import React, { useState } from "react";
import DashboardCard from "../../components/dashboard/DashboardCard";
import { FaUser, FaUsers } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import DashboardSales from "../../components/dashboard/DashboardSales";
import DashboardManpower from "../../components/dashboard/DashboardManpower";
import BreadCrumb from "../../components/shared/BreadCrumb";
import { ImSpinner2 } from "react-icons/im";
import { TbCoinTakaFilled } from "react-icons/tb";
import { useGetDashboardMainQuery, useGetProfileQuery } from "../../redux/features/profile/profileApi";
import { useGetHistoryQuery } from "../../redux/features/history/historyApi";

const Dashboard = () => {
  const [querySearch, setQuerySearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { data, isLoading } = useGetDashboardMainQuery(querySearch, {
    refetchOnMountOrArgChange: true,
  });
  const dashboard = data?.data;



  const { data: SaleData } = useGetProfileQuery("sort=-createdAt&limit=10", {
    refetchOnMountOrArgChange: true,
  });

  const { data: manpowerData,refetch } = useGetHistoryQuery(
    `sort=-createdAt&limit=10`,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleOrderDateFilter = () => {
    if (!startDate || !endDate) {
      return alert("please select date");
    }

    if (new Date(startDate) > new Date(endDate)) {
      return alert("invalid date input");
    }
    setQuerySearch(`startDate=${startDate}&endDate=${endDate}`);
  };

  const clearData = () => {
    setQuerySearch("");
    setStartDate("");
    setEndDate("");
  };

  return (
    <div>
      {isLoading ? (
        <>
          <div className=" flex items-center justify-center w-full h-[80vh]">
            <ImSpinner2 className=" animate-spin w-12 h-12" />
          </div>
        </>
      ) : (
        <div>
          <BreadCrumb
            title={"Admin"}
            links={[
              { title: "Admins", url: "/admin/dashboard" },
              { title: "Dashboard", url: "/admin/dashboard" },
            ]}
          />

          <div className=" bg-white mb-5 shadow-md rounded-md p-5 flex items-center justify-between gap-5">
            <div className=" w-full">
              <h2 className=" text-xl font-semibold"> Dashboard</h2>
            </div>
            <div className="w-full flex items-center gap-2">
              <div className=" w-full">
                <input
                  onChange={(e) => setStartDate(e.target.value)}
                  type={"date"}
                  value={startDate}
                  placeholder="mm/dd/yyy"
                  className="bg-white py-2.5 h-[45px] px-4 text-gray-700 outline-none rounded-md border text-sm w-full border-gray-300"
                />
              </div>{" "}
              <span className="hidden md:block"> to</span>
              <div className=" w-full">
                <input
                  onChange={(e) => setEndDate(e.target.value)}
                  type={"date"}
                  value={endDate}
                  placeholder="mm/dd/yyy"
                  className="bg-white py-2.5 h-[45px] px-4 text-gray-700 outline-none rounded-md border text-sm w-full border-gray-300"
                />
              </div>
              <button
                onClick={() => handleOrderDateFilter()}
                className="h-[45px] bg-primary flex cursor-pointer items-center justify-center text-xl px-3 rounded-md  text-white hover:btn-info"
              >
                <IoSearch />
              </button>
              {startDate && endDate && (
                <button
                  onClick={() => clearData()}
                  className="h-[45px] bg-yellow-500 flex cursor-pointer items-center justify-center text-xl px-3 rounded-md  text-white hover:btn-info"
                >
                  clear
                </button>
              )}
            </div>
          </div>

          <div className=" grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-2 md:gap-5">
            <DashboardCard
              {...{
                iconColor: "bg-lime-500",
                title: "Total Revenue",
                className: "border-b-lime-500",
                amount: dashboard?.totalAmount,
                icon: <TbCoinTakaFilled className=" text-white text-2xl" />,
                taka:true,
              }}
            />

            <DashboardCard
              {...{
                iconColor: "bg-cyan-500",
                title: "Due Amount",
                className: "border-b-cyan-500",
                amount: dashboard?.totalDueAmount,
                icon: <TbCoinTakaFilled className=" text-white text-2xl" />,
                taka:true,
              }}
            />

            <DashboardCard
              {...{
                iconColor: "bg-amber-500",
                title: "Today Due",
                className: "border-b-amber-500",
                amount: dashboard?.todayDue,
                icon: <TbCoinTakaFilled className=" text-white text-2xl" />,
                taka:true,
              }}
            />

            <DashboardCard
              {...{
                iconColor: "bg-green-500",
                title: "Total Customer",
                className: "border-b-green-500",
                amount: dashboard?.totalCustomer,
                icon: <FaUsers className=" text-white text-[20px]" />,
              }}
            />
            <DashboardCard
              {...{
                iconColor: "bg-red-500",
                title: "Payment Due",
                className: "border-b-red-500",
                amount: dashboard?.paymentDue,
                icon: <FaUsers className=" text-white" />,
              }}
            />
            <DashboardCard
              {...{
                iconColor: "bg-primary",
                title: "Today pay",
                className: "border-b-primary",
                amount: dashboard?.todayPay,
                icon: <FaUser className=" text-white" />,
              }}
            />
          </div>

          <div>
            <DashboardSales data={SaleData?.data} />
          </div>

          <div className=" mb-8">
            <DashboardManpower data={manpowerData?.data} refetch={refetch}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

import React from "react";

const CustomNumberInput = ({
  label,
  name,
  type = "text",
  placeholder,
  register,
  validation,
  trigger,
  errors,
  onChange=()=>{}
}) => {
  return (
    <div className="flex flex-col items-start w-full mt-3">
      <label
        htmlFor={name}
        className="mb-1.5 font-normal text-sm text-dark-gray"
      >
        {label}
      </label>
      <input
        className="py-[15px] h-[45px] px-[14px] font-normal  text-dark-gray rounded-md   w-full text-sm outline-none  border-[1px] focus:border-primary bg-gray-100 focus:bg-transparent"
        type={type}
        placeholder={placeholder}
        id={name}
        {...register(name, validation)}
        onKeyUp={() => trigger(name)}
        // onChange={onChange}
      />
      <small className="text-[#FF4B2B] text-xs ml-2 font-medium ">
        {errors?.[name]?.message}
      </small>
    </div>
  );
};

export default CustomNumberInput;
import React from "react";

const SearchField = ({ value, setValue, placeholder }) => {
  return (
    <div className="w-full">
      <input
        className="bg-white py-2.5 h-[45px] px-4 text-gray-700 outline-none rounded-md border text-sm w-full border-gray-300"
        placeholder={placeholder}
        type={"text"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

export default SearchField;

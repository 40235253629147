import React, { useEffect, useState } from "react";
import { LuView } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../shared/modal/DeleteModal";
import { useDeleteUserMutation } from "../../redux/features/auth/authApi";
import toast from "react-hot-toast";
import EditAdmin from "./EditAdmin";

const AdminAction = ({row,refetch}) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [open,setOpen]=useState(false)
    const [deleteUser, { isLoading, error, isSuccess }] = useDeleteUserMutation();

    useEffect(() => {
      if (isSuccess) {
        const message = "Doctor delete successfully";
        toast.success(message);
        refetch();
        setDeleteModal(false);
      }
      if (error) {
        if ("data" in error) {
          const errorData = error;
          toast.error(errorData.data.message);
        }
      }
    }, [isSuccess, error,refetch]);
  
    const handelDelete = async () => {
      await deleteUser(row?._id);
    };



  return (
    <>
      <div className="flex items-center gap-1">
        <button
        onClick={()=>setOpen(true)}
          className=" text-xl hover:text-primary duration-200"
        >
          <LuView />
        </button>
        <button
          onClick={() => setDeleteModal(true)}
          className=" text-xl hover:text-red-500 duration-200"
        >
          <RiDeleteBin6Line />
        </button>
      </div>


      <DeleteModal
        modalOPen={deleteModal}
        onDelete={() => handelDelete()}
        setModalOpen={setDeleteModal}
        title={"Delete Doctors!"}
        title2={
          "Are you sure you want to delete this doctor? This action cannot be undone."
        }
        isLoading={isLoading}
      />

      <EditAdmin row={row} open={open} setOpen={setOpen} refetch={refetch}/>
    </>
  );
};

export default AdminAction;

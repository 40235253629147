import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({title,links}) => {
  return (
    <div className="  mb-5 mt-3  w-full lg:mt-0  z-0">
      <h2 className=" font-semibold text-2xl">{title}</h2>
      <div>
        {links?.map((link,index)=>(
          <Link className="text-[#707EAE] text-sm font-[500]" key={index} to={link.url}>{link.title} {links.length - 1 > index ? "/" : ""} </Link>
        ))}
      </div>
    </div>
  );
};

export default BreadCrumb;

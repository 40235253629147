import React from "react";
import { Table } from "antd";

const CustomTable = ({ columns, data,scroll }) => {
  return (
    <>
      <div className="overflow-x-auto">
        <Table columns={columns} dataSource={data} pagination={false} scroll={scroll}/>
      </div>
    </>
  );
};

export default CustomTable;

import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/shared/BreadCrumb";
import { ImSpinner2 } from "react-icons/im";
import { Pagination } from "antd";
import CustomTable from "../../components/shared/table/CustomTable";
import AgentAdd from "../../components/agent/AgentAdd";
import AgentAction from "../../components/agent/AgentAction";
import SearchField from "../../components/shared/input/SearchField";
import { useDebounce } from "use-debounce";
import { useGetSectorsQuery } from "../../redux/features/sector/sectorApi";

const AgentList = () => {
  const [queryList, setQueryList] = useState("");
  const [page, setPage] = useState(1);
  const limit = 10;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchValue] = useDebounce(search, 1000);

  const { data, isLoading, refetch } = useGetSectorsQuery(queryList, {
    refetchOnMountOrArgChange: true,
  });

  const generateQuery = (searchValue) => {
    const queryParams = [];

    if (searchValue) {
      queryParams.push(`search=${searchValue}`);
      setPage(1);
    }

    return queryParams.join("&");
  };

  useEffect(() => {
    const query = generateQuery(searchValue);
    setQueryList(`${query}&page=${page}&limit=${limit}`);
    // refetch();
  }, [searchValue, page, limit]);

  const startIndex = (page - 1) * limit;

  const columns = [
    {
      title: "S/N",
      key: "id",
      render: (row, record, index) => (
        <span className=" text-[14px] font-normal text-info">
          {startIndex + index + 1}
        </span>
      ),
      width: "200px",
    },
    {
      title: "Line Sector Title",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.title}
        </span>
      ),
    },
    {
      title: "Line Sector Area",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.area}
        </span>
      ),
    },

    {
      title: "Actions",
      render: (row) => (
        <div>
          <AgentAction row={row} refetch={refetch} />
        </div>
      ),
      width: "100px",
    },
  ];

  const PaginationChange = (page, pageSiz) => {
    setPage(page);
  };

  const totalItems = data?.item;

  return (
    <div className=" pb-8">
      <div className=" flex items-center justify-between">
        <div>
          <h2 className=" text-lg font-semibold">Line Sector List</h2>
          <BreadCrumb
            links={[
              { title: "Admins", url: "/admin/dashboard" },
              { title: "Line Sector List", url: "#" },
            ]}
          />
        </div>
        <button
          onClick={() => setOpen(true)}
          className="py-2 px-5 bg-primary text-white rounded-md"
        >
          Add Line Sector
        </button>
      </div>

      <div>
        {isLoading ? (
          <>
            <div className=" flex items-center justify-center w-full h-[80vh]">
              <ImSpinner2 className=" animate-spin w-12 h-12" />
            </div>
          </>
        ) : (
          <div className=" bg-white shadow-sm rounded-md">
            <div className=" p-5 md:w-[50%] w-full">
              <SearchField
                value={search}
                setValue={setSearch}
                placeholder="Search by title area ..."
              />
            </div>

            <CustomTable columns={columns} data={data?.data} />
            <div className=" py-4 flex items-center justify-between px-4">
              <div className="text-light-black font-medium text-[14px] text-[#68769F]">
                {`Showing ${page * limit - limit + 1} - ${Math.min(
                  page * limit,
                  totalItems
                )} of ${totalItems}`}
              </div>
              <Pagination
                defaultCurrent={page}
                total={totalItems}
                pageSize={limit}
                onChange={PaginationChange}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
      </div>

      <AgentAdd open={open} setOpen={setOpen} refetch={refetch} />
    </div>
  );
};

export default AgentList;

import React, { useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import CustomTable from "../../components/shared/table/CustomTable";
import { Pagination } from "antd";
import BreadCrumb from "../../components/shared/BreadCrumb";
import { formatDate } from "../../utils/commonFun";
import IncomeReporteFilter from "../../components/income/IncomeReporteFilter";
import { useGetIncomeQuery } from "../../redux/features/income/incomeApi";

const IncomeReport = () => {
  const [querySearch, setQuerySearch] = useState("");
  const [page, setPage] = useState(1);
  const limit = 10;
  const { data, isLoading, refetch } = useGetIncomeQuery(querySearch, {
    refetchOnMountOrArgChange: true,
  });

  const totalItems = data?.item;

  const columns = [
    {
      title: "Invoice Number",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.invoice_number}
        </span>
      ),
    },
    {
      title: "Name",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.name}
        </span>
      ),
    },
    {
      title: "Head",
      key: "id",
      render: (row) => (
        <div className=" flex flex-col">
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.head?.title}
          </span>
        </div>
      ),
    },
    {
      title: " Date",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {formatDate(row?.date)}
        </span>
      ),
    },
    {
      title: "Amount",
      render: (product) => (
        <div className="flex flex-col text-info">
          <span className="text-[14px] font-medium">
            <span className=" text-base">৳</span> {product.amount}
          </span>
        </div>
      ),
    },
    {
      title: "Description",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.description}
        </span>
      ),
    },
  ];

  const PaginationChange = (page, pageSiz) => {
    setPage(page);
  };

  return (
    <div className=" pb-8">
      <BreadCrumb
        title={"Expense Report"}
        links={[
          { title: "Admins", url: "/admin/dashboard" },
          { title: "Expense Report", url: "#" },
        ]}
      />

      {isLoading ? (
        <>
          <div className=" flex items-center justify-center w-full h-[80vh]">
            <ImSpinner2 className=" animate-spin w-12 h-12" />
          </div>
        </>
      ) : (
        <div className=" bg-white shadow-sm mt-5 rounded-md">
          <IncomeReporteFilter
            setQuery={setQuerySearch}
            page={page}
            setPage={setPage}
            limit={limit}
            refetch={refetch}
            query={querySearch}
            totalAmount={data?.totalAmount}
          />
          <CustomTable columns={columns} data={data?.data} />
          <div className=" py-4 flex items-center justify-between px-4">
            <div className="text-light-black font-medium text-[14px] text-[#68769F]">
              {`Showing ${page * limit - limit + 1} - ${Math.min(
                page * limit,
                totalItems
              )} of ${totalItems}`}
            </div>
            <Pagination
              defaultCurrent={page}
              total={totalItems}
              pageSize={limit}
              onChange={PaginationChange}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default IncomeReport;

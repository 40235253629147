import { apiSlice } from "../api/apiSlice";


export const incomeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createIncome: builder.mutation({
      query: (data) => ({
        url: "income/create",
        method: "POST",
        body: data,
      }),
    }),
    getIncome: builder.query({
      query: (query) => ({
        url: `income?${query}`,
        method: "GET",
      }),
    }),
    getSingleIncome: builder.query({
      query: (id) => ({
        url: `income/${id}`,
        method: "GET",
      }),
    }),
    editIncome: builder.mutation({
      query: ({ id, data }) => ({
        url: `income/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteIncome: builder.mutation({
      query: (id) => ({
        url: `income/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
    useCreateIncomeMutation,
    useGetIncomeQuery,
    useEditIncomeMutation,
    useDeleteIncomeMutation
} = incomeApi;

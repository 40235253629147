import { apiSlice } from "../api/apiSlice";


export const headApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createHead: builder.mutation({
      query: (data) => ({
        url: "head/create",
        method: "POST",
        body: data,
      }),
    }),
    getHead: builder.query({
      query: (query) => ({
        url: `head?${query}`,
        method: "GET",
      }),
    }),
    getSingleHead: builder.query({
      query: (id) => ({
        url: `head/${id}`,
        method: "GET",
      }),
    }),
    editHead: builder.mutation({
      query: ({ id, data }) => ({
        url: `head/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteHead: builder.mutation({
      query: (id) => ({
        url: `head/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
    useCreateHeadMutation,
    useGetHeadQuery,
    useEditHeadMutation,
    useDeleteHeadMutation,
} = headApi;

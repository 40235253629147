
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import SearchField from "../shared/input/SearchField";
import HeadingSelect from "../shared/input/HeadingSelect";
import { useGetSectorsQuery } from "../../redux/features/sector/sectorApi";

const SalesFilter = ({ setQuery, page, limit, setPage, refetch }) => {
  const [category, setCategory] = useState(null);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState("");
  const [searchValue] = useDebounce(search, 1000);

  const { data } = useGetSectorsQuery("limit=100", {
    refetchOnMountOrArgChange: true,
  });

  const options = data?.data?.map((item) => {
    return { label: item?.title, value: item?._id };
  });

  const generateQuery = (searchValue,status) => {
    const queryParams = [];
    if (searchValue) {
      queryParams.push(`search=${searchValue}`);
      setPage(1);
    }

    if (status) {
      queryParams.push(`line_sector=${status}`);
      setPage(1);
    }
    return queryParams.join("&");
  };

  useEffect(() => {
    const query = generateQuery(searchValue,status);
    setQuery(`${query}&page=${page}&limit=${limit}`);
    // refetch();
  }, [searchValue, status, page, limit,setQuery]);

  const clear = () => {
    setSearch("");
    setStatus(null);
    setCategory(null);
  };

  return (
    <div className=" p-5">
      <div className="flex items-center gap-5">
        <div className="w-[50%]">
          <SearchField
            value={search}
            setValue={setSearch}
            placeholder="Search ..."
          />
        </div>
        <div className=" flex w-[50%] items-center gap-5">
          <HeadingSelect
            value={status}
            setValue={setStatus}
            placeholder={"Select Line Sector"}
            options={options}
          />

          {search || status || category ? (
            <button
              onClick={() => clear()}
              className=" px-5 h-[45px]   py-2 bg-red-500 rounded-md text-white"
            >
              {" "}
              Clear
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesFilter;

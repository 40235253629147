import React from "react";
import {
  MdOutlineCalendarToday,
  MdOutlineDashboard,
  MdPayments,
} from "react-icons/md";
import { RiBloggerLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import SubMenu from "./SubMenu";
import { FiUser } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { HiMiniUserGroup } from "react-icons/hi2";
import { PiArrowsInCardinal, PiMapPinAreaBold } from "react-icons/pi";
import { CgCalendarDue } from "react-icons/cg";
import { TbHistoryToggle, TbReportAnalytics } from "react-icons/tb";
import { SiExpensify } from "react-icons/si";

const Sidebar = () => {
  const path = useLocation();

  const pathname = path.pathname;

  const subMenusListBlog = [
    {
      name: "Grahok Profile",
      icon: HiMiniUserGroup,
      active: 14,
      activeData: "/admin/profile",
      menus: [
        {
          title: "Add Profile",
          link: "/admin/profile/add",
        },
        {
          title: "Profile List",
          link: "/admin/profile",
        },
      ],
    },
  ];

  const subMenusListIncome = [
    {
      name: "Income",
      icon: PiArrowsInCardinal,
      active: 13,
      activeData: "/admin/income",
      menus: [
        {
          title: "Add Income",
          link: "/admin/income/add",
        },
        {
          title: "Income List",
          link: "/admin/income",
        },
        {
          title: "Head List",
          link: "/admin/income/head",
        },
      ],
    },
  ];

  const subMenusListExpane = [
    {
      name: "Expense",
      icon: SiExpensify,
      active: 14,
      activeData: "/admin/expense",
      menus: [
        {
          title: "Add Expense",
          link: "/admin/expense/add",
        },
        {
          title: "Expense List",
          link: "/admin/expense",
        },
        {
          title: "Head List",
          link: "/admin/expense/head",
        },
      ],
    },
  ];

  const subMenusListReport = [
    {
      name: "Report",
      icon: TbReportAnalytics,
      active: 13,
      activeData: "/admin/report",
      menus: [
        {
          title: "Payment Report",
          link: "/admin/report/payment",
        },
        {
          title: "Expense Report",
          link: "/admin/report/expense",
        },
        {
          title: "Income Report",
          link: "/admin/report/income",
        },
      ],
    },
  ];

  const subSetting = [
    {
      name: "Setting",
      icon: IoSettingsOutline,
      active: 14,
      activeData: "/admin/setting",
      menus: [
        {
          title: "Change Password",
          link: "/admin/setting/change-password",
          icon: <RiBloggerLine />,
        },
      ],
    },
  ];

  const isActive = (href) => {
    return pathname === href;
  };

  return (
    <div className="w-[17rem] bg-white max-w-[15rem] border-r h-screen">
      <div className=" flex items-center py-[20px] justify-center">
        <img src="/image/logo.png" className=" w-[200px]" alt="logo" />
      </div>

      <div className=" h-[76vh] overflow-y-auto ">
        <ul className=" px-3 mt-3 flex items-start w-full flex-col gap-1">
          <li className=" w-full">
            <Link
              to={"/admin/dashboard"}
              className={`flex items-center text-sm py-3 relative group rounded-lg  font-semibold px-5 gap-2 ${
                isActive("/admin/dashboard")
                  ? " bg-primary text-white"
                  : "text-slate-500 hover:bg-gray-200"
              }`}
            >
              <MdOutlineDashboard size={16} className={" min-w-max"} />
              <span>Dashboard</span>
            </Link>
          </li>

          {subMenusListBlog?.map((menu, index) => (
            <div key={index} className="flex w-full flex-col gap-1">
              <SubMenu data={menu} />
            </div>
          ))}

          <li className=" w-full">
            <Link
              to={"/admin/sector"}
              className={`flex items-center text-sm py-3 relative group rounded-lg  font-semibold px-5 gap-2 ${
                isActive("/admin/sector")
                  ? " bg-primary text-white"
                  : "text-slate-500 hover:bg-gray-200"
              }`}
            >
              <PiMapPinAreaBold size={16} className={" min-w-max"} />
              <span>Line Sector</span>
            </Link>
          </li>

          <li className=" w-full">
            <Link
              to={"/admin/due-today"}
              className={`flex items-center text-sm py-3 relative group rounded-lg  font-semibold px-5 gap-2 ${
                isActive("/admin/due-today")
                  ? " bg-primary text-white"
                  : "text-slate-500 hover:bg-gray-200"
              }`}
            >
              <MdOutlineCalendarToday size={16} className={" min-w-max"} />
              <span>Today Due List</span>
            </Link>
          </li>

          <li className=" w-full">
            <Link
              to={"/admin/due"}
              className={`flex items-center text-sm py-3 relative group rounded-lg  font-semibold px-5 gap-2 ${
                isActive("/admin/due")
                  ? " bg-primary text-white"
                  : "text-slate-500 hover:bg-gray-200"
              }`}
            >
              <CgCalendarDue size={16} className={" min-w-max"} />
              <span>Due List</span>
            </Link>
          </li>

          <li className=" w-full">
            <Link
              to={"/admin/payment"}
              className={`flex items-center text-sm py-3 relative group rounded-lg  font-semibold px-5 gap-2 ${
                isActive("/admin/payment")
                  ? " bg-primary text-white"
                  : "text-slate-500 hover:bg-gray-200"
              }`}
            >
              <MdPayments size={16} className={" min-w-max"} />
              <span>Payment</span>
            </Link>
          </li>

          {subMenusListIncome?.map((menu, index) => (
            <div key={index} className="flex w-full flex-col gap-1">
              <SubMenu data={menu} />
            </div>
          ))}

          {subMenusListExpane?.map((menu, index) => (
            <div key={index} className="flex w-full flex-col gap-1">
              <SubMenu data={menu} />
            </div>
          ))}

          {subMenusListReport?.map((menu, index) => (
            <div key={index} className="flex w-full flex-col gap-1">
              <SubMenu data={menu} />
            </div>
          ))}

          <li className=" w-full">
            <Link
              to={"/admin/admin-list"}
              className={`flex items-center text-sm py-3 relative group rounded-lg  font-semibold px-5 gap-2 ${
                isActive("/admin/admin-list")
                  ? " bg-primary text-white"
                  : "text-slate-500 hover:bg-gray-200"
              }`}
            >
              <FiUser size={16} className={" min-w-max"} />
              <span>Admin List</span>
            </Link>
          </li>

          {subSetting?.map((menu, index) => (
            <div key={index} className="flex w-full flex-col gap-1">
              <SubMenu data={menu} />
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

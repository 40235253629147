import { apiSlice } from "../api/apiSlice";
import { addTags } from "../auth/authSlice";

export const sectorApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSector: builder.mutation({
      query: (data) => ({
        url: "sector/create",
        method: "POST",
        body: data,
      }),
    }),
    getSectors: builder.query({
      query: (query) => ({
        url: `sector?${query}`,
        method: "GET",
      }),
    }),
    editSector: builder.mutation({
      query: ({ id, data }) => ({
        url: `sector/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteSector: builder.mutation({
      query: (id) => ({
        url: `sector/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
    useCreateSectorMutation,
    useGetSectorsQuery,
    useEditSectorMutation,
    useDeleteSectorMutation,
} = sectorApi;

import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line, RiHistoryFill } from "react-icons/ri";
import DeleteModal from "../shared/modal/DeleteModal";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { useDeleteProfileMutation } from "../../redux/features/profile/profileApi";

const SaleAction = ({ row, refetch }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteProfile, { isLoading, error, isSuccess }] =
    useDeleteProfileMutation();

  useEffect(() => {
    if (isSuccess) {
      const message = "Profile delete successfully";
      toast.success(message);
      refetch();
      setDeleteModal(false);
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error, refetch]);

  const handelDelete = async () => {
    await deleteProfile(row?._id);
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <Link
          to={`/admin/profile/history/${row?._id}?name=${row?.name}`}
          className=" text-xl hover:text-primary duration-200"
        >
          <RiHistoryFill />
        </Link>
        <Link
          to={`/admin/profile/${row?._id}`}
          className=" text-[19px] hover:text-primary duration-200"
        >
          <FiEdit />
        </Link>
        <button
          onClick={() => setDeleteModal(true)}
          className=" text-xl hover:text-red-500 duration-200"
        >
          <RiDeleteBin6Line />
        </button>
      </div>

      <DeleteModal
        modalOPen={deleteModal}
        onDelete={() => handelDelete()}
        setModalOpen={setDeleteModal}
        title={"Delete Customer Profile!"}
        title2={
          "Are you sure you want to delete this Customer Profile? This action cannot be undone."
        }
        isLoading={isLoading}
      />
    </>
  );
};

export default SaleAction;

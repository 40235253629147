import React, { useEffect, useState } from "react";
import CustomModal from "../shared/modal/CustomModal";
import { useForm } from "react-hook-form";
import CustomInput from "../shared/input/CustomInput";
import CustomSelectMultiple from "../shared/input/CustomSelectMultiple";
import CustomNumberInput2 from "../shared/input/CustomNumberInput2";
import { useCreateHistoryMutation } from "../../redux/features/history/historyApi";
import toast from "react-hot-toast";

const DuePaymentModal = ({ row, open, setOpen, refetch }) => {
  const [month, setMonth] = useState();
  const [cost, setCost] = useState();

  const [createHistory, { isLoading, isSuccess, error }] =
    useCreateHistoryMutation();

  const options = row?.due_months?.map((item) => {
    return { label: item, value: item };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm();

  useEffect(() => {
    if (isSuccess) {
      const message = "payment successfully";
      toast.success(message);
      refetch();
      setOpen(false);
      reset();
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error, refetch]);

  const handelCost = (str) => {
    const input = str?.replace(/\D/g, "");
    const formatted = formatNumberWithCommas(input);
    setCost(formatted);
  };

  const onlyNumber = (str) => {
    const result = str?.match(/\d+/g)?.join("") || "";
    return Number(result);
  };

  const formatNumberWithCommas = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    if (month) {
      const total = row?.line_charge * month?.length;
      setCost(total.toString());
    }
  }, [month, row]);

  useEffect(() => {
    if (row) {
      setValue("name", row?.name);
      setValue("line_Id", row?.line_id);
      setMonth(row?.due_months);
      // setCost(row?.amount_due.toString());
    }
  }, [row, setValue]);

  const onSubmit = async (values) => {
    const data = {
      profileId: row?._id,
      payment: onlyNumber(cost),
      month: month,
      sector: row?.line_sector?._id,
      note: values?.note,
      method: values?.method,
      total_due:Number(row?.amount_due)
    };

    await createHistory(data);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      width={700}
      title="Payment Model"
    >
      <div>
        <h2 className=" text-base font-semibold">
          Total Due Amount :{" "}
          <span className=" font-normal "><span className="  font-mono">৳</span>{row?.amount_due}</span>
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
          <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
            <CustomInput
              label={"Customer Name"}
              type={"text"}
              readOnly={true}
              register={register("name", {
                required: {
                  value: false,
                  message: "Title is required",
                },
              })}
              error={errors.name}
              placeholder={"Line Sector Title"}
            />

            <CustomInput
              label={"Line Id"}
              type={"text"}
              readOnly={true}
              register={register("line_Id", {
                required: {
                  value: false,
                  message: "Title is required",
                },
              })}
              error={errors.passenger_name}
              placeholder={"Line Sector Title"}
            />
          </div>

          <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
            <CustomSelectMultiple
              label={"Line Sector"}
              value={month}
              setValue={setMonth}
              options={options}
              required={true}
              onChange={handleChange}
              placeholder={"Select Line Sector"}
            />
            <CustomNumberInput2
              label="Due Amount"
              name="due_amount"
              type="text"
              placeholder="Enter Line Charge"
              register={register}
              value={cost}
              validation={{
                required: false,
                pattern: {
                  value: /^[0-9,]+$/,
                  message: "Total Amount consist of digits only.",
                },
              }}
              onChange={(e) => handelCost(e.target.value)}
              trigger={trigger}
              errors={errors}
            />
          </div>

          <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
            <CustomInput
              label={"Payment Method"}
              type={"text"}
              readOnly={false}
              register={register("method", {
                required: {
                  value: true,
                  message: "method is required",
                },
              })}
              error={errors.method}
              placeholder={"Payment method"}
            />

            <CustomInput
              label={"Note"}
              type={"text"}
              readOnly={false}
              register={register("note", {
                required: {
                  value: false,
                  message: "Title is required",
                },
              })}
              error={errors.passenger_name}
              placeholder={"Enter Note..."}
            />
          </div>

          <div className=" mt-7 mb-3 flex items-center justify-center">
            <button
              type="submit"
              className=" py-2 text-sm font-medium px-7 bg-primary text-white rounded-md"
            >
              {isLoading ? "Loading..." : "Payment"}
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default DuePaymentModal;

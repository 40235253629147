import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../shared/modal/DeleteModal";
import toast from "react-hot-toast";
import UpdateAgent from "./UpdateAgent";
import { FiEdit } from "react-icons/fi";
import { useDeleteSectorMutation } from "../../redux/features/sector/sectorApi";

const AgentAction = ({ row, refetch }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteSector, { isLoading, error, isSuccess }] =
    useDeleteSectorMutation();

  useEffect(() => {
    if (isSuccess) {
      const message = "Line Sector Delete successfully";
      toast.success(message);
      refetch();
      setDeleteModal(false);
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error, refetch]);

  const handelDelete = async () => {
    await deleteSector(row?._id);
  };

  return (
    <>
      <div className="flex items-center gap-1">
        <button
          onClick={() => setOpen(true)}
          className=" text-xl hover:text-primary duration-200"
        >
          <FiEdit />
        </button>
        <button
          onClick={() => setDeleteModal(true)}
          className=" text-xl hover:text-red-500 duration-200"
        >
          <RiDeleteBin6Line />
        </button>
      </div>

      <DeleteModal
        modalOPen={deleteModal}
        onDelete={() => handelDelete()}
        setModalOpen={setDeleteModal}
        title={"Delete Line Sector!"}
        title2={
          "Are you sure you want to delete this Line Sector? This action cannot be undone."
        }
        isLoading={isLoading}
      />

      <UpdateAgent row={row} open={open} setOpen={setOpen} refetch={refetch} />
    </>
  );
};

export default AgentAction;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomInput from "../../components/shared/input/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useLoginMutation } from "../../redux/features/auth/authApi";
import toast from "react-hot-toast";


const SignIn = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [login, { isSuccess, isLoading, error, data }] = useLoginMutation();

  const tokenString = localStorage.getItem("token_doctor");


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (tokenString) {
      navigate("/admin/dashboard");
    }
  }, [tokenString]);

  useEffect(() => {
    if (isSuccess) {
      const message = "Login successfully";
      toast.success(message);
      navigate("/admin/dashboard");

      // router.push("/auth/login");
      reset();
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error, data]);

  const onSubmit = async (values) => {
    const { email, password } = values;

    const data = {
      email: email,
      password: password,
    };
    await login(data);
  };

  return (
    <div className=" flex items-center h-screen w-full justify-center">
      <div className="w-full px-8 py-12 md:w-[500px] bg-white rounded-lg shadow-md">
        <div className=" flex items-center justify-center">
          <img src="/image/logo.png" className=" w-[300px]" alt="logo" />
        </div>
        <div className="my-5 flex items-center justify-center flex-col">
          <h1 className="text-dark-gray text-[28px] font-bold">Admin Panel</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <CustomInput
              label={"Email"}
              type={"email"}
              register={register("email", {
                required: {
                  value: true,
                  message: "Please Enter User Email",
                },
              })}
              error={errors.lastName}
              placeholder={"Enter Email"}
            />
          </div>

          <div className="mb-2">
            <div className="flex flex-col items-start w-full mt-5 relative">
              <div className=" w-full flex mb-1.5 items-center justify-between">
                <label
                  htmlFor="otp"
                  className=" font-medium text-base text-dark-gray"
                >
                  {" Password"}
                </label>
                <p className="underline text-info text-sm font-medium">
                  <Link to={"/forget-password"} className="font-bold text-primary">
                    Forgot Password?
                  </Link>
                </p>
              </div>
              <input
                className="py-[15px] h-[45px] px-[14px] font-normal  text-dark-gray rounded-md   w-full text-sm outline-none  border-[1px] focus:border-primary bg-gray-100 focus:bg-transparent"
                type={show ? "text" : "password"}
                {...register("password", {
                  required: true,
                })}
                placeholder={"Enter Password"}
              />
              <div className=" absolute top-[58%] right-[10px]">
                <button type="button" onClick={() => setShow((pre) => !pre)}>
                  {show ? <IoMdEye /> : <IoMdEyeOff />}
                </button>
              </div>
            </div>
          </div>
          <div className="mt-6 w-full">
            <button className=" py-3 px-5 w-full bg-primary text-sm font-medium text-white rounded-md">
              {isLoading ? "Loading..." : "Login"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { usePasswordChangeMutation } from "../../redux/features/auth/authApi";
import { useSelector } from "react-redux";
import BreadCrumb from "../../components/shared/BreadCrumb";

function ChangePassword() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const [passwordChange, { isLoading, isSuccess, error }] =
    usePasswordChangeMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  useEffect(() => {
    if (isSuccess) {
      const message = "Password Change successfully";
      toast.success(message);
      reset();
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error]);

  const onSubmit = async (values) => {
    const data = {
      oldPassword: values?.oldPassword,
      password: values?.newPassword,
    };

    await passwordChange(data);
  };

  return (
    <div>
      <BreadCrumb
        title="Change Password"
        links={[
          { title: "Admins", url: "/admin/dashboard" },
          { title: "Change Password", url: "/admin/setting/change-password" },
        ]}
      />
      <div className=" mt-5">
        <div className=" bg-white shadow-md rounded-xl p-8">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-[0px]">
            <div className=" flex items-center gap-5 md:flex-row flex-col">
              <div className="w-full mt-5">
                <label htmlFor="" className=" text-sm mb-1 font-normal">
                  Old Password
                </label>
                <div className=" relative">
                  <input
                    type={show2 ? "text" : "password"}
                    placeholder="Password"
                    {...register("oldPassword", {
                      required: {
                        value: true,
                        message: "Please add password",
                      },
                    })}
                    className="py-[15px] h-[50px] px-[14px] font-normal  text-dark-gray rounded-[10px] w-full text-sm outline-none  border-[1px] focus:border-primary bg-gray-100 focus:bg-transparent"
                  />
                  <div className=" absolute top-[15px] right-[15px]">
                    <span
                      className=" cursor-pointer"
                      onClick={() => setShow2(!show2)}
                    >
                      {show2 ? <FaRegEye /> : <FaRegEyeSlash />}
                    </span>
                  </div>
                </div>
                <label className="label">
                  {errors.oldPassword?.type === "required" && (
                    <span className=" text-sm mt-1 text-red-500">
                      {errors.oldPassword.message}
                    </span>
                  )}
                </label>
              </div>
            </div>

            <div className=" flex items-center md:flex-row md:gap-5 flex-col">
              <div className="w-full mt-5">
                <label htmlFor="" className=" text-sm mb-1 font-normal">
                  New Password
                </label>
                <div className=" relative">
                  <input
                    type={show ? "text" : "password"}
                    placeholder="Password"
                    {...register("newPassword", {
                      required: {
                        value: true,
                        message: "Please add password",
                      },
                    })}
                    className="py-[15px] h-[50px] px-[14px] font-normal  text-dark-gray rounded-[10px] w-full text-sm outline-none  border-[1px] focus:border-primary bg-gray-100 focus:bg-transparent"
                  />
                  <div className="absolute top-[15px] right-[15px]">
                    <span
                      className=" cursor-pointer"
                      onClick={() => setShow(!show)}
                    >
                      {show ? <FaRegEye /> : <FaRegEyeSlash />}
                    </span>
                  </div>
                </div>
                <label className="label">
                  {errors.newPassword?.type === "required" && (
                    <span className=" text-sm mt-1 text-red-500">
                      {errors.newPassword.message}
                    </span>
                  )}
                </label>
              </div>

              <div className="w-full mt-5">
                <label htmlFor="" className="text-sm  mb-1 font-normal">
                  confirmation Password
                </label>
                <div className=" relative">
                  <input
                    type={show1 ? "text" : "password"}
                    placeholder="Confirmation Password"
                    {...register("confirm_password", {
                      required: true,
                      validate: (val) => {
                        if (watch("newPassword") !== val) {
                          return "Your passwords do no match";
                        }
                      },
                    })}
                    className="py-[15px] h-[50px] px-[14px] font-normal  text-dark-gray rounded-[10px] w-full text-sm outline-none  border-[1px] focus:border-primary bg-gray-100 focus:bg-transparent"
                  />
                  <div className="absolute top-[15px] right-[15px]">
                    <span
                      className=" cursor-pointer"
                      onClick={() => setShow1(!show1)}
                    >
                      {show1 ? <FaRegEye /> : <FaRegEyeSlash />}
                    </span>
                  </div>
                </div>
                {errors?.confirm_password?.message && (
                  <p className=" text-red-500 text-sm mt-1">
                    Your passwords does not match!
                  </p>
                )}
              </div>
            </div>

            <div className=" mt-6 flex items-center justify-center">
              <button
                type="submit"
                disabled={isLoading}
                className=" bg-primary text-white px-6 py-3 text-sm rounded-lg font-semibold"
              >
                {isLoading ? "Loading..." : "Change Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;

import React from "react";
import CustomTable from "../shared/table/CustomTable";
import { formatDate } from "../../utils/commonFun";

const DashboardSales = ({ data }) => {
  const columns = [
    {
      title: "Line Id",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.line_id}
        </span>
      ),
      width: "100px",
    },
    {
      title: "Customer Name",
      key: "id",
      render: (row) => (
        <div className=" flex flex-col">
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.name}
          </span>
        </div>
      ),
      width: "150px",
    },

    {
      title: "Phone",
      key: "id",
      render: (row) => (
        <div className=" flex flex-col">
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.phone}
          </span>
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.other_phone}
          </span>
        </div>
      ),
    },
    {
      title: "Line Sector",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.line_sector?.title}
        </span>
      ),
    },
    {
      title: "Join Date",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {formatDate(row?.join_date)}
        </span>
      ),
    },
    {
      title: "Bill Remainder",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {formatDate(row?.bill_remainder_date)}
        </span>
      ),
    },
    {
      title: "Address",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.address}
        </span>
      ),
    },
    {
      title: "Line Charge",
      render: (product) => (
        <div className="flex flex-col text-info">
          <span className="text-[14px] font-medium">
            <span className=" text-base">৳</span> {product.line_charge}
          </span>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className=" bg-white shadow-sm mt-8 rounded-md">
        <div className="p-5 flex items-center gap-2">
          <h2 className=" text-xl font-semibold">Customer List </h2>{" "}
          <span className=" text-sm font-medium text-blue-500">
            Latest 10 data
          </span>
        </div>
        <CustomTable columns={columns} data={data} />
      </div>
    </div>
  );
};

export default DashboardSales;

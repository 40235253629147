import React, { useEffect } from "react";
import CustomModal from "../shared/modal/CustomModal";
import { useForm } from "react-hook-form";
import CustomInput from "../shared/input/CustomInput";
import toast from "react-hot-toast";
import CustomTextArea from "../shared/input/CustomTextArea";
import { useEditHeadMutation } from "../../redux/features/head/headApi";

const HeadEdit = ({ open, setOpen, refetch, row }) => {
  const [editHead, { error, isSuccess, isLoading }] = useEditHeadMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (row) {
      setValue("title", row?.title);
      setValue("description", row?.description);
    }
  }, [row, setValue]);

  useEffect(() => {
    if (isSuccess) {
      const message = "Head add successfully";
      toast.success(message);
      setOpen(false);
      refetch();
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error, refetch, setOpen]);

  const onSubmit = async (values) => {
    const data = {
      title: values?.title,
      description: values?.description,
    };

    const id = row?._id;

    await editHead({ id, data });
  };

  return (
    <CustomModal open={open} setOpen={setOpen} width={500} title="Update Head">
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
          <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
            <CustomInput
              label={"Title"}
              type={"text"}
              register={register("title", {
                required: {
                  value: true,
                  message: "title is required",
                },
              })}
              error={errors.title}
              placeholder={"Enter title"}
            />
          </div>

          <div className=" flex items-start justify-between md:flex-row flex-col gap-5">
            <CustomTextArea
              label={"Description"}
              type={"email"}
              register={register("description", {
                required: {
                  value: false,
                  message: "email date is required",
                },
              })}
              error={errors.description}
              placeholder={"Enter Description"}
            />
          </div>

          <div className=" mt-7 mb-3 flex items-center justify-center">
            <button
              type="submit"
              className=" py-2 text-sm font-medium px-7 bg-primary text-white rounded-md"
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default HeadEdit;

import React, { useState } from "react";
import DuePaymentDateChange from "./DuePaymentDateChange";

const DueChangeAction = ({ row, refetch }) => {
  const [open1, setOpen2] = useState(false);
  return (
    <>
      <div className="flex items-center gap-2">
        <button
          onClick={() => setOpen2(true)}
          className=" bg-yellow-500 text-white py-1 px-2 rounded-md text-sm font-medium"
        >
          Change Date
        </button>
      </div>

      <DuePaymentDateChange
        row={row}
        open={open1}
        setOpen={setOpen2}
        refetch={refetch}
      />
    </>
  );
};

export default DueChangeAction;

import React, { useState } from "react";
import BreadCrumb from "../../components/shared/BreadCrumb";
import { ImSpinner2 } from "react-icons/im";
import { Pagination } from "antd";
import CustomTable from "../../components/shared/table/CustomTable";
import AdminListFilter from "../../components/adminList/AdminListFilter";
import AddAdmin from "../../components/adminList/AddAdmin";
import { useGetAllUserQuery } from "../../redux/features/auth/authApi";
import AdminAction from "../../components/adminList/AdminAction";

const AdminList = () => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);

  const { data, isLoading,refetch } = useGetAllUserQuery("", {
    refetchOnMountOrArgChange: true,
  });

  const columns = [
    {
      title: "Name",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] font-normal text-info">
          {row?.fullName}
        </span>
      ),
      width:"200px"
    },
    {
      title: "Role",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.role}
        </span>
      ),
    },
    {
      title: "Mobile No",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.phone}
        </span>
      ),
    },
    {
      title: "Email",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.email}
        </span>
      ),
    },
    {
      title: "Status",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] py-1 px-2 bg-green-500 text-white capitalize font-normal rounded-md">
          {row?.status}
        </span>
      ),
    },
    {
      title: "Actions",
      render: (row) => <div >
        <AdminAction row={row} refetch={refetch} />
      </div>,
      width: "100px",
    },
  ];

  const PaginationChange = (page, pageSiz) => {
    setPage(page);
  };

  const totalItems = data?.data?.data?.total;

  return (
    <div className=" pb-8">
      <div className=" flex items-center justify-between">
        <div>
          <h2 className=" text-lg font-semibold">Admin List</h2>
          <BreadCrumb
            links={[
              { title: "Admins", url: "/admin/dashboard" },
              { title: "Admin list", url: "/admin/admin_list" },
            ]}
          />
        </div>
        <button
          onClick={() => setOpen(true)}
          className="py-2 px-5 bg-primary text-white rounded-md"
        >
          Add Admin
        </button>
      </div>

      <div>
        {isLoading ? (
          <>
            <div className=" flex items-center justify-center w-full h-[80vh]">
              <ImSpinner2 className=" animate-spin w-12 h-12" />
            </div>
          </>
        ) : (
          <div className=" bg-white shadow-sm rounded-md">
            <AdminListFilter
              query={query}
              setQuery={setQuery}
              page={page}
              setPage={setPage}
              limit={limit}
              // refetch={refetch}
            />
            <CustomTable columns={columns} data={data?.data?.data?.result} />
            <div className=" py-4 flex items-center justify-between px-4">
              <div className="text-light-black font-medium text-[14px] text-[#68769F]">
                {`Showing ${page * limit - limit + 1} - ${Math.min(
                  page * limit,
                  totalItems
                )} of ${totalItems}`}
              </div>
              <Pagination
                defaultCurrent={page}
                total={totalItems}
                pageSize={limit}
                onChange={PaginationChange}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
      </div>

      <AddAdmin open={open} setOpen={setOpen} refetch={refetch} />
    </div>
  );
};

export default AdminList;

import React, { useEffect, useState } from "react";
import CustomModal from "../shared/modal/CustomModal";
import { useForm } from "react-hook-form";
import CustomInput from "../shared/input/CustomInput";
import CustomNumberInput from "../shared/input/CustomNumberInput";
import CustomSelect from "../shared/input/CustomSelect";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useAddRegisterMutation } from "../../redux/features/auth/authApi";
import toast from "react-hot-toast";

const AddAdmin = ({ open, setOpen,refetch }) => {
  const [role, setRole] = useState(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [addRegister, { error, isSuccess, isLoading }] =
    useAddRegisterMutation();

  const roleDate = [
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Staff",
      value: "staff",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    if (isSuccess) {
      const message = "Admin add successfully";
      toast.success(message);
      reset();
      setRole(null);
      setOpen(false);
      refetch()
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error]);

  const onSubmit = async (values) => {
    const data = {
      fullName: values?.fullName,
      email: values?.email,
      phone: values?.phone,
      password: values?.newPassword,
      role: role,
    };

    await addRegister(data);
  };

  return (
    <CustomModal open={open} setOpen={setOpen} width={800} title="Add Admin">
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
          <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
            <CustomInput
              label={"Admin Name"}
              type={"text"}
              register={register("fullName", {
                required: {
                  value: true,
                  message: "Name is required",
                },
              })}
              error={errors.passenger_name}
              placeholder={"Enter admin Name"}
            />
            <CustomNumberInput
              label="Mobile No"
              name="phone"
              type="text"
              placeholder="Enter admin Phone"
              register={register}
              validation={{
                required: "Phone is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Mobile No  must consist of digits only.",
                },
              }}
              trigger={trigger}
              errors={errors}
            />
          </div>

          <div className=" flex items-start justify-between md:flex-row flex-col gap-5">
            <CustomInput
              label={"Email"}
              type={"email"}
              register={register("email", {
                required: {
                  value: true,
                  message: "email date is required",
                },
              })}
              error={errors.issue_date}
              placeholder={"Enter email"}
            />
            <CustomSelect
              label={"Role"}
              value={role}
              setValue={setRole}
              options={roleDate}
              required={true}
              placeholder={"Select Role"}
            />
          </div>

          <div className=" flex items-start md:flex-row md:gap-5 flex-col">
            <div className="w-full mt-5">
              <label htmlFor="" className=" text-sm mb-1 font-normal">
                New Password
              </label>
              <div className=" relative">
                <input
                  type={show ? "text" : "password"}
                  placeholder="Password"
                  {...register("newPassword", {
                    required: {
                      value: true,
                      message: "Please add password",
                    },
                  })}
                  className="py-[15px] h-[45px] px-[14px] font-normal  text-dark-gray rounded-[10px] w-full text-sm outline-none  border-[1px] focus:border-primary bg-gray-100 focus:bg-transparent"
                />
                <div className="absolute top-[15px] right-[15px]">
                  <span
                    className=" cursor-pointer"
                    onClick={() => setShow(!show)}
                  >
                    {show ? <FaRegEye /> : <FaRegEyeSlash />}
                  </span>
                </div>
              </div>
              <label className="label">
                {errors.newPassword?.type === "required" && (
                  <span className=" text-sm mt-1 text-red-500">
                    {errors.newPassword.message}
                  </span>
                )}
              </label>
            </div>

            <div className="w-full mt-5">
              <label htmlFor="" className="text-sm  mb-1 font-normal">
                Confirm Password
              </label>
              <div className=" relative">
                <input
                  type={show1 ? "text" : "password"}
                  placeholder="Confirmation Password"
                  {...register("confirm_password", {
                    required: true,
                    validate: (val) => {
                      if (watch("newPassword") !== val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                  className="py-[15px] h-[45px] px-[14px] font-normal  text-dark-gray rounded-[10px] w-full text-sm outline-none  border-[1px] focus:border-primary bg-gray-100 focus:bg-transparent"
                />
                <div className="absolute top-[15px] right-[15px]">
                  <span
                    className=" cursor-pointer"
                    onClick={() => setShow1(!show1)}
                  >
                    {show1 ? <FaRegEye /> : <FaRegEyeSlash />}
                  </span>
                </div>
              </div>
              {errors?.confirm_password?.message && (
                <p className=" text-red-500 text-sm mt-1">
                  Your passwords does not match!
                </p>
              )}
            </div>
          </div>

          <div className=" mt-7 mb-3 flex items-center justify-center">
            <button
              type="submit"
              className=" py-2 text-sm font-medium px-7 bg-primary text-white rounded-md"
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default AddAdmin;

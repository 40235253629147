import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/shared/BreadCrumb";
import { useGetProfileQuery } from "../../redux/features/profile/profileApi";
import { useForm } from "react-hook-form";
import {
  useCreateHistoryMutation,
  useGetHistoryQuery,
} from "../../redux/features/history/historyApi";
import toast from "react-hot-toast";
import CustomInput from "../../components/shared/input/CustomInput";
import CustomSelectMultiple from "../../components/shared/input/CustomSelectMultiple";
import CustomNumberInput2 from "../../components/shared/input/CustomNumberInput2";
import CustomSelect from "../../components/shared/input/CustomSelect";
import HistoryAction from "../../components/allHistory/HistoryAction";
import { formatDate } from "../../utils/commonFun";
import CustomTable from "../../components/shared/table/CustomTable";

const PaymentPage = () => {
  const [status, setStatus] = useState(null);
  const [selectData, setSelectData] = useState();
  const [month, setMonth] = useState();
  const [cost, setCost] = useState();

  const { data } = useGetProfileQuery("status=due", {
    refetchOnMountOrArgChange: true,
  });

  const [createHistory, { isLoading, isSuccess, error }] =
    useCreateHistoryMutation();

  const { data: historyData, refetch } = useGetHistoryQuery(
    "sort=-createdAt&limit=5",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm();


  useEffect(() => {
    if (status) {
      const filterData = data?.data?.find((item) => item?._id === status);
      setSelectData(filterData);
    }
  }, [status, data]);

  useEffect(() => {
    if (isSuccess) {
      const message = "payment successfully";
      toast.success(message);
      reset();
      refetch()
    }
    if (error) {
      if ("data" in error) {
        const errorData = error;
        toast.error(errorData.data.message);
      }
    }
  }, [isSuccess, error, reset,refetch]);

  const handelCost = (str) => {
    const input = str?.replace(/\D/g, "");
    const formatted = formatNumberWithCommas(input);
    setCost(formatted);
  };

  const onlyNumber = (str) => {
    const result = str?.match(/\d+/g)?.join("") || "";
    return Number(result);
  };

  const formatNumberWithCommas = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    if (month) {
      const total = selectData?.line_charge * month?.length;
      setCost(total.toString());
    }
  }, [month, selectData]);

  useEffect(() => {
    if (selectData) {
      setValue("name", selectData?.name);
      setValue("line_Id", selectData?.line_id);
      setMonth(selectData?.due_months);
      // setCost(selectData?.amount_due.toString());
    }
  }, [selectData, setValue]);

  const onSubmit = async (values) => {
    const data = {
      profileId: selectData?._id,
      payment: onlyNumber(cost),
      month: month,
      sector: selectData?.line_sector?._id,
      note: values?.note,
      method: values?.method,
      total_due: Number(selectData?.amount_due),
    };

    await createHistory(data);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const options = data?.data?.map((item) => {
    return { label: item?.line_id + " - " + item?.name, value: item?._id };
  });

  const options2 = selectData?.due_months?.map((item) => {
    return { label: item, value: item };
  });

  const columns = [
    {
      title: "Line Id",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {row?.profile?.line_id}
        </span>
      ),
      width: "100px",
    },
    {
      title: "Customer Name",
      key: "id",
      render: (row) => (
        <div className=" flex flex-col">
          <span className=" text-[14px] capitalize font-normal text-info">
            {row?.profile?.name}
          </span>
        </div>
      ),
      width: "150px",
    },
    {
      title: "Amount",
      render: (row) => (
        <div className="flex flex-col text-info">
          <span className="text-[14px] font-medium">
            <span className=" text-base">৳</span> {row?.payment}
          </span>
        </div>
      ),
    },
    {
      title: " Method",
      render: (row) => (
        <div className="flex flex-col text-info">
        <span className="text-[14px] font-medium">{row?.method}</span>
      </div>
      ),
    },
    {
      title: "Month",
      render: (row) => (
        <div className=" flex items-start flex-col gap-1">
          {row?.due_months?.map((item, index) => (
            <span
              key={index}
              className=" text-[14px] capitalize font-normal text-info"
            >
              {item}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "Payment Date",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] capitalize font-normal text-info">
          {formatDate(row?.createdAt)}
        </span>
      ),
    },
    {
      title: "Note",
      render: (row) => (
        <div className="flex flex-col text-info">
          <span className="text-[14px] font-medium">{row?.note}</span>
        </div>
      ),
      width: "200px",
    },
    {
      title: "Action",
      render: (product) => (
        <>
          <HistoryAction row={product} refetch={refetch} />
        </>
      ),
    },
  ];

  return (
    <div>
      <BreadCrumb
        title="Payment"
        links={[
          { title: "Admins", url: "/admin/dashboard" },
          { title: "Payment", url: "#" },
        ]}
      />

      <div className=" bg-white shadow-md mt-5 rounded-md p-7">
        <div className=" mb-7 w-full md:w-[60%]">
          <CustomSelect
            label={"Sector Customer"}
            value={status}
            setValue={setStatus}
            placeholder={"Select Customer"}
            options={options}
          />
        </div>

        {selectData && (
          <div>
            <h2 className=" text-base font-semibold">
              Customer Name :{" "}
              <span className=" font-normal">{selectData?.name}</span>
            </h2>{" "}
            <h2 className=" text-base font-semibold">
              Total Due Amount :{" "}
              <span className=" font-normal">৳ {selectData?.amount_due}</span>
            </h2>
          </div>
        )}

        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
            <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
              <CustomInput
                label={"Customer Name"}
                type={"text"}
                readOnly={true}
                register={register("name", {
                  required: {
                    value: false,
                    message: "Title is required",
                  },
                })}
                error={errors.passenger_name}
                placeholder={"Line Sector Title"}
              />

              <CustomInput
                label={"Line Id"}
                type={"text"}
                readOnly={true}
                register={register("line_Id", {
                  required: {
                    value: false,
                    message: "Title is required",
                  },
                })}
                error={errors.passenger_name}
                placeholder={"Line Sector Title"}
              />
            </div>

            <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
              <CustomSelectMultiple
                label={"Line Sector"}
                value={month}
                setValue={setMonth}
                options={options2}
                required={true}
                onChange={handleChange}
                placeholder={"Select Line Sector"}
              />
              <CustomNumberInput2
                label="Due Amount"
                name="due_amount"
                type="text"
                placeholder="Enter Line Charge"
                register={register}
                value={cost}
                validation={{
                  required: false,
                  pattern: {
                    value: /^[0-9,]+$/,
                    message: "Total Amount consist of digits only.",
                  },
                }}
                onChange={(e) => handelCost(e.target.value)}
                trigger={trigger}
                errors={errors}
              />
            </div>

            <div className=" flex items-start justify-between gap-5 md:flex-row flex-col">
              <CustomInput
                label={"Payment Method"}
                type={"text"}
                readOnly={false}
                register={register("method", {
                  required: {
                    value: true,
                    message: "method is required",
                  },
                })}
                error={errors.method}
                placeholder={"Payment method"}
              />

              <CustomInput
                label={"Note"}
                type={"text"}
                readOnly={false}
                register={register("note", {
                  required: {
                    value: false,
                    message: "Title is required",
                  },
                })}
                error={errors.passenger_name}
                placeholder={"Enter Note..."}
              />
            </div>

            <div className=" mt-7 mb-3 flex items-center justify-center">
              <button
                type="submit"
                className=" py-2 text-sm font-medium px-7 bg-primary text-white rounded-md"
              >
                {isLoading ? "Loading..." : "Payment"}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className=" bg-white mb-7 mt-7 py-2 shadow-md rounded-md">
      <div className=" py-3 px-5">
        <h2 className=" text-xl font-semibold">List 5 Payment List</h2>
      </div>
        <CustomTable columns={columns} data={historyData?.data} />
      </div>
    </div>
  );
};

export default PaymentPage;

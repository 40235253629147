import "./App.css";
import { Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import CustomRoutes from "./routes/CustomRoutes";
import Layout from "./components/layout/Layout";
import Dashboard from "./page/dashboard/Dashboard";
import SignIn from "./page/auth/SignIn";
import SalesAdd from "./page/dashboard/SalesAdd";
import SalesList from "./page/dashboard/SalesList";
import AdminList from "./page/dashboard/AdminList";
import AgentList from "./page/dashboard/AgentList";
import ChangePassword from "./page/dashboard/ChangePassword";
import ForgetPassword from "./page/auth/ForgetPassword";
import ProfileEdit from "./page/dashboard/ProfileEdit";
import PaymentDueList from "./page/dashboard/PaymentDueList";
import TodayDue from "./page/dashboard/TodayDue";
import AllHistory from "./page/dashboard/AllHistory";
import ProfileHistory from "./page/dashboard/ProfileHistory";
import PaymentPage from "./page/dashboard/PaymentPage";
import InvoicePage from "./page/dashboard/InvoicePage";
import IncomeHead from "./page/dashboard/IncomeHead";
import AddIncome from "./page/dashboard/AddIncome";
import IncomeList from "./page/dashboard/IncomeList";
import ExpenseHead from "./page/dashboard/ExpenseHead";
import ExpenseAdd from "./page/dashboard/ExpenseAdd";
import ExpenseList from "./page/dashboard/ExpenseList";
import ExpenseReport from "./page/dashboard/ExpenseReport";
import IncomeReport from "./page/dashboard/IncomeReport";

function App() {
  return (
    <>
      <div>
        <CustomRoutes>
          <Route path="/" element={<SignIn />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-new-password/:token" element={<ForgetPassword />} />
          {/* ------------------------admin dashboard route--------------------- */}
          <Route path="/admin" element={<Layout></Layout>}>
            <Route path="/admin/dashboard" element={<Dashboard />} />
             {/* ------------------------profile--------------------- */}
            <Route path="/admin/profile/add" element={<SalesAdd />} />
            <Route path="/admin/profile" element={<SalesList />} />
            <Route path="/admin/profile/:id" element={<ProfileEdit />} />
            <Route path="/admin/profile/history/:id" element={<ProfileHistory />} />
            <Route path="/admin/invoice/:id" element={<InvoicePage />} />


            <Route path="/admin/sector" element={<AgentList />} />
            <Route path="/admin/admin-list" element={<AdminList />} />
            <Route path="/admin/due" element={<PaymentDueList />} />
            <Route path="/admin/due-today" element={<TodayDue />} />
            <Route path="/admin/payment" element={<PaymentPage />} />

            <Route path="/admin/income/head" element={<IncomeHead />} />
            <Route path="/admin/income/add" element={<AddIncome />} />
            <Route path="/admin/income" element={<IncomeList />} />

            <Route path="/admin/expense/head" element={<ExpenseHead />} />
            <Route path="/admin/expense/add" element={<ExpenseAdd />} />
            <Route path="/admin/expense" element={<ExpenseList />} />

            <Route path="/admin/report/payment" element={<AllHistory />} />
            <Route path="/admin/report/expense" element={<ExpenseReport />} />
            <Route path="/admin/report/income" element={<IncomeReport />} />

            <Route path="/admin/admin-list" element={<ChangePassword />} />
          </Route>
        </CustomRoutes>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </>
  );
}

export default App;

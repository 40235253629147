import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/shared/BreadCrumb";
import { ImSpinner2 } from "react-icons/im";
import { Pagination } from "antd";
import CustomTable from "../../components/shared/table/CustomTable";
import { useGetHeadQuery } from "../../redux/features/head/headApi";
import HeadAction from "../../components/head/HeadAction";
import AddHead from "../../components/head/AddHead";
import SearchField from "../../components/shared/input/SearchField";
import { useDebounce } from "use-debounce";

const ExpenseHead = () => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchValue] = useDebounce(search, 1000);

  const { data, isLoading, refetch } = useGetHeadQuery(query, {
    refetchOnMountOrArgChange: true,
  });

  console.log(data)

  const columns = [
    {
      title: "Title",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] font-normal text-info">{row?.title}</span>
      ),
    },

    {
      title: "Description",
      key: "id",
      render: (row) => (
        <span className=" text-[14px] font-normal text-info">
          {row?.description}
        </span>
      ),
    },

    {
      title: "Actions",
      render: (row) => (
        <div>
          <HeadAction row={row} refetch={refetch} />
        </div>
      ),
      width: "100px",
    },
  ];

  const PaginationChange = (page, pageSiz) => {
    setPage(page);
  };


  const generateQuery = (searchValue) => {
    const queryParams = [];
    if (searchValue) {
      queryParams.push(`search=${searchValue}`);
      setPage(1);
    }
    return queryParams.join("&");
  };

  useEffect(() => {
    const query = generateQuery(searchValue);
    setQuery(`${query}&type=expense&page=${page}&limit=${limit}`);
    // refetch();
  }, [searchValue, page, limit]);

  const totalItems = data?.item;

  return (
    <div className=" pb-8">
      <div className=" flex items-center justify-between">
        <div>
          <h2 className=" text-lg font-semibold">Expense Head</h2>
          <BreadCrumb
            links={[
              { title: "Admins", url: "/admin/dashboard" },
              { title: "Expense Head", url: "#" },
            ]}
          />
        </div>
        <button
          onClick={() => setOpen(true)}
          className="py-2 px-5 bg-primary text-white rounded-md"
        >
          Add Head
        </button>
      </div>

      <div>
        {isLoading ? (
          <>
            <div className=" flex items-center justify-center w-full h-[80vh]">
              <ImSpinner2 className=" animate-spin w-12 h-12" />
            </div>
          </>
        ) : (
          <div className=" bg-white shadow-sm rounded-md">
            <div className=" p-5">
              <div className="w-[50%]">
                <SearchField
                  value={search}
                  setValue={setSearch}
                  placeholder="Search title..."
                />
              </div>
            </div>
            <CustomTable columns={columns} data={data?.data} />
            <div className=" py-4 flex items-center justify-between px-4">
              <div className="text-light-black font-medium text-[14px] text-[#68769F]">
                {`Showing ${page * limit - limit + 1} - ${Math.min(
                  page * limit,
                  totalItems
                )} of ${totalItems}`}
              </div>
              <Pagination
                defaultCurrent={page}
                total={totalItems}
                pageSize={limit}
                onChange={PaginationChange}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
      </div>

      <AddHead
        open={open}
        setOpen={setOpen}
        refetch={refetch}
        type={"expense"}
      />
    </div>
  );
};

export default ExpenseHead;
